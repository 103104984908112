export const TOAST_MESSAGE = {
    SUCCESS: {
        WORKOUT_CREATED: 'Workout created successfully.',
        WORKOUT_UPDATED: 'Workout updated successfully.',
        FORUM_CREATED: 'Forum created successfully.',
        TEMPLATE_CREATED: 'Template created successfully.'
    },
    ERROR: {
        WORKOUT_CREATION: "An error occurred while creating workout.",
        WORKOUT_UPDATED: "An error occurred while updating workout.",
        FORUM_CREATION: "An error occurred while creating forum.",
        TEMPLATE_CREATION: "An error occurred while creating template."
    }

}