import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import RoundedCheckBox from '../../../Components/CommonComponents/RoundedCheckBox';
import { SubTitle } from '../../../Components/CustomComponents';
import SelectFieldComponent from '../../../Components/CustomComponents/SelectFieldComponent';
import { LANGUAGE_CODE_ENUM } from '../../../Constant/constants';
import { updateLanguage } from '../../../Data/Features/languageSlice';
import { useToggleNotificationMutation } from '../../../Data/services/userAuthApi';
import { useLocalization } from '../../../Hooks/useLocalization';

const AdditionalInfoForm = () => {
    const STRING = useLocalization()
    const dispatch = useDispatch()
    const user = useSelector(state => state.authInfo.user);
    const languageCode = useSelector(state => state.language.code);

    const [toggleNotification] = useToggleNotificationMutation()



    const optionList = [
        { label: "English", value: LANGUAGE_CODE_ENUM.ENGLISH },
        { label: "Chinese", value: LANGUAGE_CODE_ENUM.CHINESE }
    ]



    const handleToggleNotification = async () => {
        await toggleNotification({ status: !user.is_pushNotification })
    }

    const handleLanguageChange = async (value) => {
        dispatch(updateLanguage(value))
    }

    return (
        <div>

            <SubTitle text={STRING.Languages} />

            <br></br>

            <Row>
                <Col sm={12} md={6}>
                    <SelectFieldComponent
                        defaultValue={languageCode}
                        optionsList={optionList}
                        onSelectChange={handleLanguageChange}
                        className={"select bg-darkJungleGreen"}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <div className='d-flex flex-row justify-content-between align-items-center mt-1 notification'>

                        <p className='m-0'> {STRING.PushNotification} </p>

                        <RoundedCheckBox
                            value={user.is_pushNotification}
                            handleChange={handleToggleNotification}
                        />

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default AdditionalInfoForm
