import React, { createContext, useContext } from "react";
import { useSocket } from "../../../Context/SocketContext";


export const ChatContext = createContext();

export function useChatContext() {
    return useContext(ChatContext)
}


export default function ChatContextProvider({ children }) {
    const socket = useSocket();

    return (
        <ChatContext.Provider >
            {children}
        </ChatContext.Provider>
    )

}