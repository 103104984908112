import _ from "lodash";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, EmptyContainer, SubTitle } from "../../Components/CustomComponents";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useGetBadgeQuery, useGetWorkoutDaysQuery } from "../../Data/services/userApi";
import { formatDate } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";

const Accolades = () => {
  const STRING = useLocalization()
  const { image_url, name, createdAt } = useSelector(state => state.authInfo.user);

  const { data: badgeData, isFetching: isBadgeFetching } = useGetBadgeQuery();
  const { data: workoutData, isFetching: isWorkoutFetching } = useGetWorkoutDaysQuery();

  return (
    <div className="Accolades focus-in-expand">
      <SubTitle text={STRING.Accolades} />
      <div className="first-row">
        <div className="left-side">
          <img src={image_url} alt="" />
          <div className="user-details">
            <SubTitle text={name} classes="fs-5" />
            <p>{`${STRING.MemberSince} ${formatDate(createdAt)}`}</p>
          </div>
        </div>
        <div className="right-side scale-in-center-slow">
          <Button
            text={isWorkoutFetching ? <Spinner size="sm" /> : `${(workoutData?.data?.count ?? 0)} ${STRING.WorkoutDays}`}
            type={INPUT_TYPES.SUBMIT}
            classes="fs-6 fw-light px-4 py-1"
          />
        </div>
      </div>
      <div className="second-row">
        <SubTitle text={STRING.Badges} />
        <div className="badges-container">
          {isBadgeFetching ? <EmptyContainer text={<Spinner />} /> :
            _.isEmpty(badgeData?.data) ? <EmptyContainer text={STRING.BadgesNotFound} /> :
              badgeData?.data.map((badge, index) => (
                <div className="badge" key={badge.slug}>
                  <img
                    src={badge.image_url}
                    alt=""
                    width="90"
                    className="rounded-4 mt-4 mb-2"
                  />
                  <p className="m-0">{badge.title} </p>
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
};

export default Accolades;
