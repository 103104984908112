import _ from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Checkbox, SubTitle } from "../../Components/CustomComponents";
import FreeTemplateViewModal from "../../Components/Modals/FreeTemplateViewModal";
import PremiumTemplateViewModal from "../../Components/Modals/PremiumTemplateViewModal";
import { useLocalization } from "../../Hooks/useLocalization";
import AllListing from "./Components/AllListing";
import CustomNavbar from "./Components/CustomNavbar";
import PostListing from "./Components/PostListing";
import TemplateListing from "./Components/TemplateListing";
import WorkoutListing from "./Components/WorkoutListing";

const Dashboard = ({ setPage }) => {
  const STRING = useLocalization()
  const [selectedValue, setSelectedValue] = useState(null);
  const [freeTemplate, setFreeTemplate] = useState({});
  const [premiumTemplate, setPremiumTemplate] = useState({});

  const [activeLink, setActiveLink] = useState(STRING.All);

  const handleChangeActive = (link) => {
    setActiveLink(link);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (selectedValue === value) {
      setSelectedValue(null);
    } else {
      setSelectedValue(value);
    }
  };

  const fireEventForParent = () => {
    const event = new Event('showTemplate');
    window.dispatchEvent(event);
  }

  const handleShowFreeTemplateModalOpen = (data = {}) => {
    setFreeTemplate(data);
    localStorage.setItem('showTemplate', true);
    fireEventForParent();
  }
  const handleShowFreeTemplateModalClose = () => {
    setFreeTemplate({});
    localStorage.setItem('showTemplate', false);
    fireEventForParent();
  }
  const handleShowPremiumTemplateModalOpen = (data = {}) => {
    setPremiumTemplate(data);
    localStorage.setItem('showTemplate', true);
    fireEventForParent();
  }
  const handleShowPremiumTemplateModalClose = () => {
    setPremiumTemplate(false);
    localStorage.setItem('showTemplate', false);
    fireEventForParent();
  }


  // const handleModalClose = () => setImageModal(false);

  // const handleImageModalOpen = (tempImagePath) => {
  //   setTempImagePath(tempImagePath);
  //   setImageModal(true);
  // };


  // const { unityProvider, isLoaded, loadingProgression, unload, requestFullscreen, addEventListener, removeEventListener } = useUnityContext({
  //   loaderUrl: "Baccarat/Build/WebGL.loader.js",
  //   dataUrl: "Baccarat/Build/WebGL.data",
  //   frameworkUrl: "Baccarat/Build/WebGL.framework.js",
  //   codeUrl: "Baccarat/Build/WebGL.wasm",
  // });

  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  // const loadingPercentage = Math.round(loadingProgression * 100);

  async function handleClickBack() {
    // await unload();
    // requestFullscreen(true);
    // Ready to navigate to another page.
    // handleModalClose();
  }

  // useEffect(() => {
  // addEventListener("ExitGame", handleModalClose);
  // return () => {
  //   removeEventListener("ExitGame", handleModalClose);
  // };
  // }, [addEventListener, removeEventListener, handleModalClose]);


  return (
    <div className={`Dashboard focus-in-expand-slow `}>

      {!_.isEmpty(freeTemplate) ? <FreeTemplateViewModal data={freeTemplate} handleModalClose={handleShowFreeTemplateModalClose} /> : ""}
      {!_.isEmpty(premiumTemplate) ? <PremiumTemplateViewModal data={premiumTemplate} handleModalClose={handleShowPremiumTemplateModalClose} /> : ""}

      <div className='headerContainer'>
        <Row className="w-100 align-items-center">
          <Col sm={12} md={12} lg={5} className='heading'>
            <SubTitle text={STRING.Dashboard} classes="lc-1" />
          </Col>
          <Col sm={12} md={6} lg={2} className={`align-items-center d-flex justify-content-end freePaidOptions`}>
            <div className={`d-flex flex-row checkBoxes scale-in-center-slow`}>
              <Checkbox
                classes="m-2 "
                label={STRING.Free}
                value='free'
                checked={selectedValue === 'free'}
                onChange={handleCheckboxChange}
              />
              <Checkbox
                classes="m-2 "
                label={STRING.Paid}
                value='paid'
                checked={selectedValue === 'paid'}
                onChange={handleCheckboxChange}
              />
            </div>
          </Col>
          <Col sm={12} md={6} lg={5} className='secondMenu'>
            <CustomNavbar activeLink={activeLink} handleChangeActive={handleChangeActive} />
          </Col>
        </Row>
      </div>

      <Row className={` mt-2 mb-2`} gutter={[0, 24]}>
        {
          (activeLink !== STRING.All) ? "" :
            <AllListing
              key={activeLink + '/' + selectedValue}
              selectedValue={selectedValue}
              setPage={setPage}
              handleShowFreeTemplateModalOpen={handleShowFreeTemplateModalOpen}
              handleShowPremiumTemplateModalOpen={handleShowPremiumTemplateModalOpen}

            />
        }
        {
          (activeLink !== STRING.Post) ? "" :
            <PostListing
              key={activeLink + '/' + selectedValue}
              selectedValue={selectedValue}
              setPage={setPage}
            />
        }
        {
          (activeLink !== STRING.Workout) ? ""
            : <WorkoutListing
              key={activeLink + '/' + selectedValue}
              selectedValue={selectedValue}
              setPage={setPage}
            />
        }
        {
          (activeLink !== STRING.Templates) ? ""
            :
            <TemplateListing
              key={activeLink + '/' + selectedValue}
              selectedValue={selectedValue}
              handleShowFreeTemplateModalOpen={handleShowFreeTemplateModalOpen}
              handleShowPremiumTemplateModalOpen={handleShowPremiumTemplateModalOpen}
            />
        }
      </Row>
    </div>
  );
};

export default Dashboard;
