import React, { useState } from "react";
import { ROUTES } from "./Route";
import Profile from "./Profile";
import PostView from "../../Components/PostDetails/PostView";
import PostViewPremium from "../../Components/PostDetails/PostViewPremium";
import WorkoutView from "../../Components/PostDetails/WorkoutView";
import WorkoutViewPremium from "../../Components/PostDetails/WorkoutViewPremium";
import FreeTemplateViewModal from "../../Components/Modals/FreeTemplateViewModal";
import PremiumTemplateViewModal from "../../Components/Modals/PremiumTemplateViewModal";

export default function PROFILE() {
  const [page, setPage] = useState({
    route: ROUTES.PROFILE,
    prev_route: "",
    data: {}
  });

  const handlePostBackButton = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.PROFILE,
      data: {}
    })
    )
  }

  const handleWorkoutBackButton = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.PROFILE,
      data: {}
    })
    )
  }

  const handleTemplateClose = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.PROFILE,
      data: {}
    })
    )
  }

  const handleSimilarPostClick = (data) => {
    setPage(prev => {
      return {
        ...prev,
        route: !!data.is_paid ? ROUTES.PREMIUM_POST : ROUTES.POST,
        data: data
      }
    });
  }

  return (
    <>
      {page.route === ROUTES.TEMPLATE ? <FreeTemplateViewModal data={page.data} handleModalClose={handleTemplateClose} /> : ""}
      {page.route === ROUTES.PREMIUM_TEMPLATE ? <PremiumTemplateViewModal data={page.data} handleModalClose={handleTemplateClose} /> : ""}
      {
        (page.route === ROUTES.POST) ? <PostView handleSimilarClick={handleSimilarPostClick} data={page.data} handleBackButtonClick={handlePostBackButton} /> :
          (page.route === ROUTES.PREMIUM_POST) ? <PostViewPremium handleSimilarClick={handleSimilarPostClick} data={page.data} handleBackButtonClick={handlePostBackButton} /> :
            (page.route === ROUTES.WORKOUT) ? <WorkoutView data={page.data} handleBackButtonClick={handleWorkoutBackButton} /> :
              (page.route === ROUTES.PREMIUM_WORKOUT) ? <WorkoutViewPremium data={page.data} handleBackButtonClick={handleWorkoutBackButton} /> :
                <Profile prev_route={page?.prev_route} setPage={setPage} />
      }
    </>
  )
}
