/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useLocalization } from '../../../Hooks/useLocalization';
// import styles from './CustomNavbar.module.scss';

const CustomNavbar = ({ activeLink, handleChangeActive }) => {

    const STRING = useLocalization()

    useEffect(() => {

        return () => {

        };
    }, [activeLink]);

    return (

        <ul className='d-flex flex-wrap justify-content-around w-100 slide-in-right-fast'>
            <li className='scale-in-center-slow'>
                <a
                    href="#"
                    className={activeLink === STRING.All ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.All)}
                >
                    {STRING.All}
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Post ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.Post)}
                >
                    {STRING.Post}
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Workout ? `${`active`}` : ''}
                    onClick={() => handleChangeActive(STRING.Workout)}
                >
                    {STRING.Workout}
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Templates ? `${`active`}` : ''}
                    onClick={() => handleChangeActive(STRING.Templates)}
                >
                    {STRING.Templates}
                </a>
            </li >
        </ul >

    )
}

export default CustomNavbar
