import React from "react";
import _ from "lodash";

// import styles from "./styles/Layout.module.scss"

import { Col, Row } from "react-bootstrap";
import ChatThreads from "./ChatThreads";
import Inbox from "./Inbox";
import { useChatContext } from "./Context/ChatContext";
import { EmptyContainer } from "../../Components/CustomComponents";
import { useLocalization } from "../../Hooks/useLocalization";

export default function DesktopLayout() {
  const STRING = useLocalization();
  const { chatRoom } = useChatContext()
  return (
    <div className="Layout">
      <Row className="row">
        <Col className="col" md={4}>
          <ChatThreads />
        </Col>
        <Col className="col" md={8}>
          {!_.isEmpty(chatRoom) ?
            <Inbox key={chatRoom?.slug} />
            :
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <EmptyContainer text={STRING.SelectChatToLoadMessage} classes="m-0" />
            </div>
          }
        </Col>
      </Row>
    </div>
  );
}
