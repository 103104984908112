import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaCircleXmark } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useGetActiveLogQuery, useStartOrStopLogMutation } from '../../Data/services/trainingApi';
import { useLocalization } from '../../Hooks/useLocalization';
import { ErrorToast } from '../../Hooks/useToast';
import ModalContainer from '../ModalContainer';

const LogTrainingModal = ({ handleModalClose }) => {
    const STRING = useLocalization()
    const INITIAL_TIMER_PAYLOAD = useMemo(() => {
        return {
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0
        }
    }, [])

    const { data, isFetched } = useSelector(state => state.training);

    const [timer, setTimer] = useState(getTimerData())
    const [startOrStopTimer] = useStartOrStopLogMutation();
    const { isFetching, isError } = useGetActiveLogQuery({}, {
        skip: isFetched,
        refetchOnMountOrArgChange: true
    })

    const [modalClass, setModalClass] = useState('logTrainingModalContainer scale-in-hor-center');


    useEffect(() => {
        if (!isFetched || _.isEmpty(data)) return
        const interval = setInterval(() => {
            setTimer(prev => {
                const newTimer = getTimerData()
                return {
                    ...prev,
                    ...newTimer
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [isFetched, data]);


    function getTimerData() {
        const payload = { ...INITIAL_TIMER_PAYLOAD };
        if (!isFetched || _.isEmpty(data)) return payload;
        const startDate = moment(data?.startedAt)
        const endDate = moment(new Date())
        const duration = moment.duration(endDate.diff(startDate))
        const { seconds, minutes, hours, days } = duration._data

        payload.seconds = seconds
        payload.minutes = minutes
        payload.hours = hours
        payload.days = days
        return payload;
    }

    const handleModalCloseWithAnimation = () => {
        setModalClass('logTrainingModalContainer scale-out-horizontal');
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };


    const handleBtnClick = async () => {
        try {
            await startOrStopTimer({ datetime: new Date() }).unwrap();
            setTimer(prev => {
                return {
                    ...prev,
                    ...INITIAL_TIMER_PAYLOAD
                }
            })
        }
        catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            else {
                ErrorToast("Error occured while updating the timer.")
            }
        }
    };


    return (
        <div>
            <ModalContainer
                handleClose={handleModalCloseWithAnimation}
            >
                <div className={modalClass}>

                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <p className='fs-4 fw-medium m-0'>
                            {STRING.LogTrainingDay}
                        </p>

                        <div className={'closeBtn'}>
                            <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalCloseWithAnimation} />
                        </div>
                    </div>

                    <br></br>

                    {/* <CustomTimer seconds={timer.seconds} minutes={timer.minutes} hours={timer.hours} days={timer.days} /> */}
                    {
                        (!isFetched && isFetching) ? <div className='py-5'><Spinner /></div> :
                            (isError || (isFetched && _.isEmpty(data))) ?
                                <button
                                    className={`my-4 rounded-button focus-in-expand check-in`}
                                    onClick={handleBtnClick}
                                >
                                    {STRING.CheckIn}
                                </button> :
                                <button
                                    className={`my-4 rounded-button focus-in-expand check-out blink`}
                                    onClick={handleBtnClick}
                                >
                                    {STRING.CheckOut}
                                </button>

                    }




                </div>
            </ModalContainer>
        </div >
    )

}

export default LogTrainingModal