import { message, Upload } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { INITIAL_TOKEN, UPLOAD_URL } from "../../config/config";
import { IMAGES } from "../../Constant/ImageConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useSocket } from "../../Context/SocketContext";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { useChatContext } from "../../Pages/Messages/Context/ChatContext";
import { Button } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import MemberSelectionCard from "./MemberSelectionCard";

const { Dragger } = Upload;

const EditGroupModal = ({ handleModalClose }) => {
  const STRING = useLocalization()
  const socket = useSocket()
  const { chatRoom: data, handleGroupDetailsUpdate, handleGroupMemberUpdate } = useChatContext()
  const { isSocketConnected, user } = useSelector(state => state.authInfo);

  const [modalClass, setModalClass] = useState(
    "editGroupModalContainer scale-in-hor-center"
  );

  const groupNameRef = useRef(null)
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [errors, setErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [mediaImage, setMediaImage] = useState(data?.image_url);

  const handleModalCloseWithAnimation = () => {
    setModalClass("editGroupModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  useEffect(() => {
    groupNameRef.current.value = data?.room_name
  }, [])

  useEffect(() => {

    if (isSocketConnected) {
      socket.on('updateGroupDetailsError_', (error) => {
        ErrorToast(error.message)
        setIsUpdating(false);
      })

      socket.on('removeMemberError_', (error) => {
        ErrorToast(error.message)
        setIsDeleting(false);
      })
    }
    return () => {
      if (isSocketConnected) {
        socket.dispose('updateGroupDetailsError_')
        socket.dispose('removeMemberError_')
      }
    }
  }, [isSocketConnected])


  useEffect(() => {
    if (isSocketConnected) {

      socket.on('updateGroupDetails_', (data) => {
        handleGroupDetailsUpdate(data)
        setIsUpdating(false);
      })

      socket.on('removeMember_', (data) => {
        handleGroupMemberUpdate(data?.payload.chat_room_slug, data?.data)
        setSelectedMembers([])
        setIsDeleting(false);
      })
    }
    return () => {
      if (isSocketConnected) {
        socket.dispose('updateGroupDetails_')
        socket.dispose('removeMember_')
      }
    }
  }, [isSocketConnected, data])


  const handleSelect = (id) => {
    setSelectedMembers((prevSelectedMembers) => {
      if (_.includes(prevSelectedMembers, id)) {
        return _.without(prevSelectedMembers, id);
      } else {
        return _.concat(prevSelectedMembers, id);
      }
    });
  }



  const handleSubmit = async () => {
    if (isUpdating) return;

    const error = {
      ...(!groupNameRef.current.value ? { groupName: "Group name is required" } : {}),
      ...(!mediaImage ? { mediaImage: "Group image is required" } : {}),
    }

    if (Object.keys(error)?.length) {
      setErrors(error)
      return
    }

    setErrors({})
    setIsUpdating(true)

    try {
      const payload = {}
      payload.chat_room_slug = data?.slug
      payload.title = groupNameRef.current.value

      if (_.isObject(mediaImage)) {
        const formData = new FormData()
        formData.append('file', mediaImage);
        const res = await axios({
          method: "POST",
          url: UPLOAD_URL,
          data: formData,
          headers: {
            "Token": INITIAL_TOKEN,
            "Content-Type": "multipart/form-data"
          },
        })
        payload.image_url = res?.data?.data?.link || '';
      }
      else {
        payload.image_url = mediaImage
      }
      socket.emit('_updateGroupDetails', payload)

    } catch (err) {
      ErrorToast("Failed to upload image");
      setIsUpdating(false)
    }
  }




  // MARK: UPLOAD IMAGE

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast(STRING.FileFormatRequired, "error", false);
    } else if (!isLt2M) {
      Toast(STRING.ImageSizeRequired, "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // upload files
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png,image/png",
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };


  const handleRemoveMembers = () => {
    if (isDeleting || _.isEmpty(selectedMembers)) return;

    setIsDeleting(true)
    const payload = {}
    payload.chat_room_slug = data?.slug
    payload.members = selectedMembers;

    socket.emit('_removeMember', payload)
  }


  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <p className="fs-4 fw-medium m-0">{STRING.EditTeam}</p>

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>

          <p className="m-0 me-auto fs-8 text-granite ">
            {STRING.CreateTeamMsg}
          </p>

          <br></br>

          {
            // MARK: DRAGGER
          }
          <Dragger Dragger {...props} className="w-100">
            {!_.isEmpty(mediaImage) ? (
              <img
                src={
                  !_.isObject(mediaImage)
                    ? mediaImage
                    : URL.createObjectURL(mediaImage)
                }
                alt="avatar"
                style={{ width: "100%", height: "100px" }}
                className="img-fluid"
              />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <img src={IMAGES.UPLOAD_ICON} alt="" />
                </p>
                <p className="ant-upload-text">
                  Drop your image here, or <span>browse</span>
                </p>
                <p className="ant-upload-hint">
                  {" "}
                  Supports: PNG, JPG, JPEG, WEBP{" "}
                </p>
              </div>
            )}
          </Dragger>

          <br></br>

          <InputGroup className="mb-2 d-flex flex-column">
            <Form.Control
              type={INPUT_TYPES.TEXT}
              placeholder={STRING.TeamName}
              className={'messageBar input-outline-orange px-3 mt-0 w-100'}
              ref={groupNameRef}
            />
            {errors?.groupName ? <p className="error_message me-auto mt-1 mb-1">{errors.groupName}</p> : ""}
          </InputGroup>

          <Button
            classes="scale-in-center-slow fs-6 mt-4 rounded-4"
            text={<div className='d-flex align-items-center justify-content-center'>
              {STRING.SaveButton}{isUpdating ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
            </div>}
            type={INPUT_TYPES.SUBMIT}
            onClick={handleSubmit}
          />

          <hr></hr>

          <div className="d-flex flex-row justify-content-between align-content-center w-100 my-2">
            <p className="rounded-2" onClick={() => { }}> {STRING.SelectMembersToRemove} </p>
            <p className="counter cursor-pointer" onClick={handleRemoveMembers}>
              <div className='d-flex align-items-center justify-content-center'>
                {STRING.RemoveBtnTxt}{isDeleting ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
              </div>
            </p>
          </div>

          {data?.members.filter(item => item.slug !== user.slug).map((member, index) => (
            <div className="w-100">
              {index > 0 ? <hr></hr> : ""}

              <MemberSelectionCard
                key={index}
                isSelected={_.includes(selectedMembers, member.slug)}
                onSelect={() => handleSelect(member.slug)}
                data={member}
              />
            </div>
          ))}

        </div>
      </ModalContainer>
    </div>
  );
};

export default EditGroupModal;
