import React from 'react'
import { useLocalization } from '../../Hooks/useLocalization'

const Logo = () => {
    const STRING = useLocalization()
    return (
        <div className='d-flex justify-content-center mb-4 bounce-in-right'>
            <img src={STRING.LOGO_PATH} alt='Better Work Logo ' style={{ width: '50%' }} />
        </div>
    )
}

export default Logo
