import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Logo from "../../Components/CommonComponents/Logo";
import { BackButton, Button, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { IMAGES } from "../../Constant/ImageConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import { SetNewPasswordPayload } from "../../Constant/PayloadConstant/SetNewPasswordPayloadConstant";
import { removeTokenForSetNewPassword } from "../../Data/services/localStorageService";
import { useSetNewPasswordMutation } from "../../Data/services/userAuthApi";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import SetNewPasswordHeading from "./Components/SetNewPasswordHeading";
import SetNewPasswordInstructions from "./Components/SetNewPasswordInstructions";
import { ACCOUNT_ENUM } from "./Route";

export default function SetPassword({ setPage }) {
  const STRING = useLocalization()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});
  // Watch the password field value to compare with confirm password
  const new_password = watch("new_password");

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleGotoLogin = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const [setPassword, { isError, isLoading, isSuccess }] =
    useSetNewPasswordMutation();

  const handleChangePassword = async (data) => {
    console.log(data);

    try {
      const response = await setPassword(data);

      console.log(response);

      if (
        response.error &&
        response.error.status >= 400 &&
        response.error.status <= 499
      ) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
      }

      if (!response.error && response.data.code === 200) {
        removeTokenForSetNewPassword();
        SuccessToast(response?.data?.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      removeTokenForSetNewPassword();
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("Something went wrong.", "error");
      }
    }
  };

  return (
    <AccountLayout
      leftSideImage={IMAGES.PASSWORD}
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.new_password?.message
          ? Toast(errors?.new_password?.message, "error")
          : errors?.confirmed_password?.message
          ? Toast(errors?.confirmed_password?.message, "error")
          : null
        : ""}

      <Logo />

      <br></br>
      <br></br>

      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />

      <SetNewPasswordHeading />

      <Form onSubmit={handleSubmit(handleChangePassword)} className="w-100">
        <Controller
          control={control}
          name={SetNewPasswordPayload.NewPassword}
          rules={{
            required: STRING.NewPasswordRequired,
            minLength: {
              value: 6,
              message: STRING.PasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.PasswordPatternRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-1"
                placeholder={STRING.SetNewPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={SetNewPasswordPayload.ConfirmedPassword}
          rules={{
            required: STRING.Confirm2PasswordRequired,
            minLength: {
              value: 6,
              message: STRING.ConfirmPasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.ConfirmPasswordPatternRequired,
            },
            validate: (value) =>
              value === new_password || STRING.ConfirmPasswordMustMatch,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-1"
                placeholder={STRING.ConfirmPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <br></br>

        <SetNewPasswordInstructions />

        <br></br>

        <Button
          classes="mt-4 mb-2"
          text={isLoading ? STRING.Sending : STRING.Continue}
          type={INPUT_TYPES.SUBMIT}
          disabled={isLoading}
        />
      </Form>
    </AccountLayout>
  );
}
