import React, { useState } from "react";
import { EmptyContainer, SubTitle } from "../../Components/CustomComponents";

import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { CARD_ENUM } from "../../Constant/constants";
import { IMAGES } from "../../Constant/ImageConstant";
import { useGetCardQuery, useRemoveCardMutation } from "../../Data/services/cardApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import ConnectAccount from "./Components/ConnectAcount";
import PaymentProcess from "./Components/PaymentPopUp";


const Payment = () => {
  const STRING = useLocalization()
  const [removeCard] = useRemoveCardMutation();

  const [deletingCard, setDeletingCard] = useState(undefined);


  const fetchCard = useGetCardQuery({
    refetchOnMountOrArgChange: false,
  });

  const { data, isLoading } = useSelector(state => state.card)

  const handleRemoveCard = async (slug) => {
    if (!_.isUndefined(deletingCard)) return;

    try {
      setDeletingCard(slug);
      const response = await removeCard(slug).unwrap();
      SuccessToast("Card removed successfully")
    } catch (error) {
      ErrorToast(error?.message || error?.data?.message);
    }
    finally {
      setDeletingCard(undefined);
    }
  }


  return (
    <div className="Payment focus-in-expand">
      <ConnectAccount />

      <SubTitle text={STRING.CurrentCard} classes="mt-2" />

      {isLoading ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> :
        _.isEmpty(data) ? <EmptyContainer text={STRING.CardNotFound} /> :
          data.map((item) => {
            return <div key={item.slug} className="selectedCard slide-in-left-slow my-3">
              <div className="cardDetails">
                <div>
                  <p> {item.card_name} </p>
                  <p> **** **** **** {item.card_number} </p>
                </div>
                {(item.card_brand === CARD_ENUM.VISA) ?
                  <img src={IMAGES.CARD_ICON} alt="" />
                  : ""}
              </div>
              <div className="deleteSectionContainer">
                <div className="deleteSection cursor-pointer" onClick={() => handleRemoveCard(item.slug)}>
                  {deletingCard === item.slug ?
                    <Spinner className="spinner" /> :
                    <MdDeleteOutline />
                  }
                </div>
              </div>
            </div>
          })
      }
      <br></br>
      <PaymentProcess />
      {/* 
      <div className="addNewCard">
        <SubTitle text={STRING.AddNewCard} />

        <div className="buttonDiv">
          <Button
            classes="px-4 fs-5 fw-bold rounded-4 scale-in-center-slow"
            text={STRING.Add}
            type={INPUT_TYPES.SUBMIT}
          />
        </div>
      </div>

      <br></br>

      <div className="cardForm">

        <InputGroup>
          <Input
            classes="mt-2 mb-2"
            placeholder={STRING.NameOnCard}
            type={INPUT_TYPES.TEXT}
          // value={value}
          // onChange={onChange}
          // onBlur={onBlur}
          />
        </InputGroup>
        <InputGroup>
          <Input
            classes="mt-2 mb-2 "
            placeholder={STRING.Card_Number}
            type={INPUT_TYPES.TEXT}
          // value={value}
          // onChange={onChange}
          // onBlur={onBlur}
          />
        </InputGroup>
        <Row>
          <Col md={6} sm={12}>

            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.Expiration}
                type={INPUT_TYPES.TEXT}
              // value={value}
              // onChange={onChange}
              // onBlur={onBlur}
              />
            </InputGroup>
          </Col>
          <Col md={6} sm={12}>

            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.CVV}
                type={INPUT_TYPES.NUMBER}
              // value={value}
              // onChange={onChange}
              // onBlur={onBlur}
              />
            </InputGroup>
          </Col>

        </Row>
      </div> */}
    </div >
  );
};

export default Payment;
