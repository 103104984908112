import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BackButton, SubTitle } from "../../Components/CustomComponents";
import { ROUTES } from "./Route";
import { useLocalization } from "../../Hooks/useLocalization";

const TermsAndCondition = ({ setPage }) => {
  const STRING = useLocalization()
  const termsData = useSelector((state) => state.staticContent.terms_condition);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackButtonClick = () => {
    setPage(ROUTES.PRIVACY);
  };

  return (
    <div className="Terms-Condition focus-in-expand-slow">
      <div className="d-flex flex-row justify-content-start align-items-center">
        <BackButton
          classes="me-3"
          isRelative={true}
          handleBackButton={handleBackButtonClick}
        />
        <SubTitle text={STRING.OnlyTermsAndCondition} />
      </div>

      <br></br>

      {termsData === null ? (
        <h1 class="shimmer">{STRING.Loading}</h1>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: termsData?.text }} />
      )}
      <br></br>
    </div>
  );
};

export default TermsAndCondition;
