import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

// import styles from './styles/Inbox.module.scss';


import axios from "axios";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { ImAttachment } from "react-icons/im";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
import { IoDocumentOutline, IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import AddMemberInGroupModal from "../../Components/Modals/AddMemberInGroupModal";
import EditGroupModal from "../../Components/Modals/EditGroupModal";
import { INITIAL_TOKEN, UPLOAD_URL } from "../../config/config";
import { MESSAGE_FILE_TYPE_ENUM, MESSAGE_FILE_TYPES, MESSAGE_IMAGE_TYPES, MESSAGE_MEDIA_TYPES, MESSAGE_TYPE_ENUM, MESSAGE_VIDEO_TYPES } from "../../Constant/constants";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useSocket } from "../../Context/SocketContext";
import { getMessageTime } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast } from "../../Hooks/useToast";
import { useChatContext } from "./Context/ChatContext";

export default function Inbox({ isMobile = false }) {
  const STRING = useLocalization()
  const socket = useSocket();
  const { isSocketConnected, user } = useSelector(state => state.authInfo)
  const { isGroup, chatRoom, setChatRoom, updateLastMessagePayload, handleGroupMemberUpdate } = useChatContext()


  const INITIAL_CHAT_HISTORY = useMemo(() => {
    return {
      isError: false,
      isLoading: true,
      data: []
    }
  }, [])


  const messageRef = useRef();
  const popoverRef = useRef(null);
  const addMemberComponentRef = useRef(null);

  const [page, setPage] = useState(1);
  const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [media, setMedia] = useState(undefined);



  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('loadChatHistory_', (data) => {
        setChatHistory(prev => {
          return {
            ...prev,
            isLoading: false,
            data: data?.data || []
          }
        })
      })
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('loadChatHistory_')
      }
    }

  }, [isSocketConnected])


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('receivedMessage_', (data) => {
        const message = data?.data
        if (message?.chat_room_slug !== chatRoom.slug) return;
        if (message.user_slug !== user.slug) {
          setChatHistory(prev => {
            return {
              ...prev,
              data: [
                ...prev?.data,
                message
              ]
            }
          })
        }
        else {
          if (_.isUndefined(data?.payload?.message_uuid)) {
            setChatHistory(prev => {
              return {
                ...prev,
                data: [
                  ...prev?.data,
                  message
                ]
              }
            })
          }
          else {
            setChatHistory(prev => {
              return {
                ...prev,
                data: [
                  ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid),
                  message
                ]
              }
            })
          }
        }
        updateLastMessagePayload(message)
        socket.emit('_resetMessageCount', { chat_room_slug: chatRoom.slug })
      })

    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('receivedMessage_')
      }
    }

  }, [isSocketConnected, chatRoom])


  useEffect(() => {
    if (!_.isEmpty(chatRoom) && (page > 1)) {
      socket.emit("_loadChatHistory", { chat_room_slug: chatRoom.slug })
    }
  }, [page])


  useEffect(() => {
    if (!chatHistory.isLoading) {
      setChatHistory(INITIAL_CHAT_HISTORY)
    }

    if (!_.isEmpty(chatRoom)) {
      socket.emit("_loadChatHistory", { chat_room_slug: chatRoom.slug })
    }
  }, [chatRoom])

  useEffect(() => {
    scrollChatContainer()
  }, [chatHistory?.data.length])


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('newMemberAdded_', (data) => {
        handleGroupMemberUpdate(data?.payload.chat_room_slug, data?.data);
        addMemberComponentRef?.current?.successCB()
      })
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('newMemberAdded_')
      }
    }
  }, [isSocketConnected])



  const handleBackButtonClick = () => {
    setChatRoom({});
  };

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  const handleLeaveGroup = () => {
    const payload = {}
    payload.chat_room_slug = chatRoom.slug
    socket?.emit('_leaveGroup', payload)
  };

  const handleEditGroup = () => {
    setEditGroupModal(true);
  };
  const handleEditGroupModalClose = useCallback(() => {
    setEditGroupModal(false);
  }, []);

  const handleAddMemberInGroupModalOpen = () => {
    setAddMemberModal(true);
  };
  const handleAddMemberInGroupModalClose = () => {
    setAddMemberModal(false);
  };

  const scrollChatContainer = () => {
    const container = document.getElementById('chatContainer')
    container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
  }


  const getFileType = () => {
    return MESSAGE_FILE_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.PDF :
      MESSAGE_IMAGE_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.IMAGE :
        MESSAGE_VIDEO_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.VIDEO : ""
  }

  const handleSendMessage = async () => {
    const message = messageRef.current.value;
    if (!message && _.isUndefined(media)) return;

    try {
      const payload = {}

      if (!_.isUndefined(media)) {
        const formData = new FormData()
        formData.append('file', media);
        const res = await axios({
          method: "POST",
          url: UPLOAD_URL,
          data: formData,
          headers: {
            "Token": INITIAL_TOKEN,
            "Content-Type": "multipart/form-data"
          },
        })

        payload.message_type = MESSAGE_TYPE_ENUM.FILE;
        payload.file_type = getFileType();
        payload.file_name = media.name;
        payload.file_url = res?.data?.data?.link
      }
      else {
        payload.message_type = MESSAGE_TYPE_ENUM.TEXT;
      }
      payload.chat_room_slug = chatRoom.slug;
      payload.message = message
      payload.message_uuid = uuidv4()
      setChatHistory(prev => {
        return {
          ...prev,
          data: [
            ...prev?.data,
            {
              ...payload,
              user_slug: user.slug,
              user_name: user.name,
              user_image: user.image_url,
              message_timestamp: new Date()
            }
          ]
        }
      })
      socket.emit('_sendMessage', payload);
      messageRef.current.value = ''
      setMedia(undefined)
    }
    catch (err) {
      ErrorToast(err?.message);
    }
  }


  const handleMediaChange = (e) => {
    setMedia(e.target.files[0] || undefined)
  }


  const handleRemoveMedia = () => {
    setMedia(undefined)
  }

  const getPreviewMediaElement = () => {
    if (MESSAGE_FILE_TYPES.includes(media.type)) {
      return <>
        <span className="documentIcon"><IoDocumentOutline /></span>
        <p className="lc-1 m-0 mt-1 mb-2 fileName">{media?.name}</p>
      </>

    }
    else if (MESSAGE_IMAGE_TYPES.includes(media.type)) {
      return <img
        className={"image"}
        src={URL.createObjectURL(media)}
        alt="Preview Image"
      />
    }
    else if (MESSAGE_VIDEO_TYPES.includes(media.type)) {
      return <video
        className="video"
        controls={true}
        autoPlay={false}
      >
        <source src={URL.createObjectURL(media)} type="video/mp4" />
      </video>
    }
    else {
      return <p className="lc-1 m-0 mt-1 mb-2 fileName">{STRING.UnsupportedFormat}</p>
    }
  }

  const getMessageMedia = (data) => {

    if (data?.file_type === MESSAGE_FILE_TYPE_ENUM.PDF) {
      return <div className="file">
        <span className="documentIcon"><IoDocumentOutline /></span>
        <a className="lc-1 m-0 mt-1 mb-2 fileName" href={data?.file_url} target="_">{data.file_name}</a>
      </div>

    }
    else if (data?.file_type === MESSAGE_FILE_TYPE_ENUM.IMAGE) {
      return <img
        className={"image"}
        src={data.file_url}
        alt="Preview Image"
      />
    }
    else if (data?.file_type === MESSAGE_FILE_TYPE_ENUM.VIDEO) {
      return <video
        className="video"
        controls={true}
        autoPlay={false}
      >
        <source src={data.file_url} type="video/mp4" />
      </video>
    }
    else {
      return <></>
    }
  }

  return (
    <>
      {
        !addMemberModal ? '' :
          <AddMemberInGroupModal
            chat_room_slug={chatRoom?.slug}
            handleModalClose={handleAddMemberInGroupModalClose}
            ref={addMemberComponentRef}
          />
      }
      {
        !editGroupModal ? "" :
          <EditGroupModal
            handleModalClose={handleEditGroupModalClose}
          />
      }


      <div className="d-flex flex-row justify-content-end">
        <p className="fw-bold">{STRING.MarkAllAsRead} </p>
      </div>
      <div className="Inbox focus-in-expand">
        <div className="titleContainer">
          <div className="userContainer">
            <div className="leftSide">
              {isMobile ? (
                <span className="backButton" onClick={handleBackButtonClick}>
                  <IoIosArrowBack />
                </span>
              ) : (
                ""
              )}
              <div className="imageDiv">
                <img
                  className="image"
                  src={chatRoom.image_url}
                  alt="User Profile"
                />
                {/* <span className="onlineIndicator"></span> */}
              </div>

              <div className="nameContainer">
                <p className="lc-1 text-white fs-4 mb-0">{chatRoom.room_name}</p>
                {/* <p className="onlineText">Online</p> */}
              </div>
            </div>

            {(!isGroup || chatRoom?.isLeaved || chatRoom?.isKicked) ? "" :
              chatRoom.is_admin ? (
                <div className="rightSide" onClick={togglePopover}>
                  <IoEllipsisHorizontalSharp color="#94A3B8" size={20} />
                  {isPopoverOpen && (
                    <div
                      ref={popoverRef}
                      className={`d-flex flex-column popover ${isPopoverOpen ? `show slide-in-right-fast` : ""
                        }`}
                    >

                      <p className="m-0 py-2" onClick={handleAddMemberInGroupModalOpen}>
                        {STRING.AddMembers}
                      </p>

                      <p className="m-0 py-2" onClick={handleEditGroup}>
                        {STRING.EditSettings}
                      </p>

                      <p className="m-0 py-2" onClick={handleLeaveGroup}>
                        {STRING.LeaveGroup}
                      </p>

                    </div>
                  )}
                </div>
              ) : (
                <div className="rightSide" onClick={togglePopover}>
                  <IoEllipsisHorizontalSharp color="#94A3B8" size={20} />
                  {isPopoverOpen && (
                    <div
                      ref={popoverRef}
                      className={`d-flex flex-column popover ${isPopoverOpen ? `show slide-in-right-fast` : ""
                        }`}
                    >
                      <p className="m-0 py-2" onClick={handleLeaveGroup}>
                        {STRING.LeaveGroup}
                      </p>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="chatContainer" id="chatContainer">

          {_.isUndefined(media) ? "" :
            <div className="documentContainer">
              <span className="closeIcon" onClick={handleRemoveMedia}><IoMdClose />  </span>
              <div className="contentContainer">
                {getPreviewMediaElement()}
              </div>
            </div>
          }

          {chatHistory?.isLoading ? <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white"><Spinner /></div> :
            _.isEmpty(chatHistory?.data) ? <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p className="m-0 text-white fs-5">{STRING.ChatMessageNotFound}</p></div> :
              (chatHistory?.data ?? []).map((item, index) => {
                const isMe = item.user_slug === user.slug;
                const isBadge = item.message_type === MESSAGE_TYPE_ENUM.BADGE
                return (
                  isBadge ? <span className="badgeMessage">{item.message}</span> :

                    <div className={`chat ${isMe ? "me" : ""}`} key={item.slug || item.message_uuid}>
                      <span className="info">{getMessageTime(item.message_timestamp)}</span>

                      {item.message_type !== MESSAGE_TYPE_ENUM.FILE ? "" :
                        <div className={`messageFile ${isMe ? "me" : ""}`}>
                          {getMessageMedia(item)}
                        </div>

                      }

                      {!item.message ? "" :
                        <p className={`message ${isMe ? "me" : ""}`}>
                          {item.message}
                        </p>
                      }
                      {isMe ? <span className="info">{STRING.Seen}</span> : ""}
                    </div>
                );
              })
          }
        </div>

        {chatRoom?.isRemoved ?
          <p className="py-2 text-white text-center">{STRING.RemovedFromGroup}</p> :
          chatRoom?.isLeaved ?
            <p className="py-2 text-white text-center">{STRING.GroupLeaved}</p> :
            <div className="messageContainer">
              <InputGroup>
                <Form.Control
                  size="lg"
                  className={`mt-1 mb-2  messageBar`}
                  placeholder={STRING.TypeMessage}
                  type={INPUT_TYPES.TEXT}
                  ref={messageRef}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      handleSendMessage();
                      e.stopPropagation()
                    }
                  }}
                // onChange={onChange}
                />
                <div className="input-before-icon">
                  <HiOutlineEmojiHappy size={20} />
                </div>
                <div className="input-after-icon">
                  <input type="file" id="document-btn" hidden className={'fileChooseInput'} onChange={handleMediaChange} multiple={false} accept={MESSAGE_MEDIA_TYPES} />
                  <label htmlFor='document-btn' className={'label'}>
                    <ImAttachment size={20} />
                  </label>
                  {/* <ImAttachment size={20} /> */}
                  {/* <TiCamera size={20} /> */}
                </div>
              </InputGroup>

              <button className={`sendButton`} onClick={handleSendMessage}>
                <FiSend color="white" />
              </button>
            </div>
        }
      </div >
    </>
  );
}
