import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import styles from "./styles/Navbar.module.scss";

import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { WEB_ROUTES } from "../../Constant/constants";
import { setValue } from "../../Data/Features/searchSlice";
import { baseRoute } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";

export default function Navbar({
  title,
  isSearchBar = true,
  isSettingButton = false,
  handleToggle,
  handleNotificationModalOpen,
  handleFilterModalOpen,
}) {
  const STRING = useLocalization()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const search = useSelector(state => state.search.text);
  const userData = useSelector((state) => state.authInfo.user);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickOutside = (event) => {
    // console.log(event.target)
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.Navbar} slide-in-top-fast`}>
      <div className={`scale-in-center-slow ${styles.titleContainer}`}>
        <span onClick={handleToggle} className={styles.toggleButton}>
          <GiHamburgerMenu />
        </span>
        <h3 className={`${styles.title}`}>{title}</h3>
      </div>
      {isSettingButton ? (
        <div
          className={styles.notificationButton}
          onClick={handleFilterModalOpen}
        >
          <LuSettings2 />
        </div>
      ) : (
        ""
      )}
      {isSearchBar ? (
        <div className={`scale-in-center-slow ${styles.search}`}>
          <div className={styles.searchButton}>
            <AiOutlineSearch />
          </div>
          <input
            className="p-2 ms-1"
            type={INPUT_TYPES.TEXT}
            placeholder={STRING.Search}
            value={search}
            onChange={(e) => dispatch(setValue(e.target.value))}
          />
        </div>
      ) : (
        ""
      )}
      {
        // todo: show horizontally when tablet show vertically
      }

      <div
        className={`scale-in-center-slow ${styles.mobileMenu}`}
        onClick={togglePopover}
      >
        <BsThreeDotsVertical />
      </div>

      {ActionBar(navigate, userData)}

      {isPopoverOpen && (
        <div
          ref={popoverRef}
          className={`${styles.popover} ${isPopoverOpen ? `${styles.show} slide-in-right-fast` : ""
            }`}
        >
          {ActionBar(navigate, { image_url: userData?.image_url })}
        </div>
      )}
    </div>
  );
}

function ActionBar(navigate, userData) {
  // create any modal here

  // const navigate = useNavigate();

  const handleViewProfile = () => {
    console.log("handleViewProfile");
    navigate(baseRoute(WEB_ROUTES.PROFILE));
  };

  return (
    <>
      {/* <div className={`scale-in-center-slow ${styles.commentButton}`} onClick={() => { }}>
                <FaRegCommentDots />
            </div> */}
      <div
        className={`scale-in-center-slow ${styles.notificationButton}`}
        onClick={() => { }}
      >
        <IoIosNotificationsOutline />
      </div>

      <div
        className={`d-flex justify-content-between align-items-center scale-in-center-slow ${styles.profile}`}
        onClick={handleViewProfile}
        role={"button"}
      >
        <img
          src={userData?.image_url}
          alt="profile logo"
          className={`${styles.image} mx-1`}
        />
        <p className="m-0 mx-1">{userData?.name}</p>
      </div>
    </>
  );
}
