import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaymentFormModal from "./PaymentForm";
import "./styles/payment.module.css"

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY
);
const PaymentProcess = () => {
  return (
    <>
      <Elements stripe={stripePromise}>
        <div className="mt-3">
          <div className="">
            <PaymentFormModal />
          </div>
        </div>
      </Elements >
    </>
  );
};

export default PaymentProcess;
