import { createSlice } from '@reduxjs/toolkit'
import { LANGUAGE_CODE_ENUM } from '../../Constant/constants'

// todo: login
const initialState = {
    code: LANGUAGE_CODE_ENUM.ENGLISH,
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        updateLanguage: (state, actions) => {
            return {
                ...state,
                code: actions.payload || LANGUAGE_CODE_ENUM.ENGLISH
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateLanguage } = languageSlice.actions

export default languageSlice.reducer