import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import {
  Button,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useSubmitFeebackMutation } from "../../Data/services/userApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast, SuccessToast } from "../../Hooks/useToast";

const Feedback = ({ setPage }) => {
  const STRING = useLocalization()
  const [submitFeedback] = useSubmitFeebackMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rating, setRating] = useState(5);
  const [feedback, setFeedback] = useState('');
  const [feedbackError, setFeedbackError] = useState('');

  const handleSubmit = async () => {
    if (isSubmitted) return;

    if (!feedback) {
      return setFeedbackError('Please enter message to submit feedback');
    }
    else {
      setFeedbackError('');
    }


    try {
      setIsSubmitted(true);
      const payload = {}
      payload.count = rating
      payload.description = feedback
      await submitFeedback(payload).unwrap();
      setFeedback('')
      setIsSubmitted(false)
      SuccessToast("Feedback submitted successfully.")
    } catch (e) {
      if (e?.data?.code === 400) {
        ErrorToast(e.data?.message)
      }
      setIsSubmitted(false)
    }
  }

  return (
    <div className="Feedback focus-in-expand">
      <SubTitle text={STRING.Feedback} />

      <div className="feedback-row">
        <div className="first-row">
          <div>
            <SubTitle text={STRING.ShareFeedback} />
            <Description text={STRING.FeedbackDescription} />
          </div>
          <div className="stars scale-in-center-slow">

            <ReactStars
              count={5}
              value={rating}
              onChange={(value) => setRating(value)}
              size={50}
              edit={true}
              activeColor="#FFCC00"
            />

          </div>
        </div>

        <div>
          <Input
            classes="textarea mb-0"
            placeholder={STRING.FeedbackPlaceholder}
            type={INPUT_TYPES.TEXTAREA}
            as={INPUT_TYPES.TEXTAREA}
            rows={5}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          {feedbackError ? <p className="error_message mt-1">{feedbackError}</p> : ""}
        </div>

        <div className="right-side mt-2">
          <Button
            classes="px-4 fs-5 fw-bold scale-in-center-slow"
            text={<div className='d-flex align-items-center justify-content-center'>
              {STRING.SubmitFeedback}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
            </div>}
            onClick={handleSubmit}
            type={INPUT_TYPES.SUBMIT}
          />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
