
import React, { useMemo } from "react";
import "./LineChart.module.css";

import moment from "moment"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { display } from "@mui/system";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ type, data, data_value }) => {

  const chartData2 = useMemo(() => {
    let labels = [];
    let values = [];

    if (type === 'month') {
      const total_days = moment(data_value).daysInMonth();
      const days_list = Array(total_days).fill(0);
      labels = days_list.map((item, index) => index + 1);
      values = [...days_list]
      data?.days?.forEach((element, index) => {
        values[element - 1] = data?.log_time[index]
      });
    }
    else if (type === 'week') {
      data?.forEach(element => {
        labels.push(element.title)
        values.push(element.time_period)
      });
    }

    else if (type === 'year') {
      data?.forEach(element => {
        labels.push(element.title)
        values.push(element.time_period)
      });
    }

    return {
      labels: labels,
      datasets: [
        {
          data: values,
          fill: true,
          borderColor: "#F68B28",
          backgroundColor: "#F68B28",
          tension: 0.6,
        },
      ],
    }
  }, []);



  const chartOptions = {
    responsive: true,
    scales: {
      xAxis: {
        display: false,
        min: 0
      },
      yAxis: {
        display: false,
        min: 0,
        afterBuildTicks: (axis) => {
          axis.ticks = [...axis.chart.scales.y.ticks];
          axis.min = axis.chart.scales.y.min;
          axis.max = axis.chart.scales.y.max;
        },
      }
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          generateLabels: function (chart) {
            return chart.data.datasets.map((dataset, index) => ({
              text: `${"Minutes"}`,
              fontColor: 'white',
              fillStyle: dataset.backgroundColor,
              hidden: !chart.isDatasetVisible(index),
              lineCap: dataset.borderCapStyle,
              lineDash: dataset.borderDash,
              lineDashOffset: dataset.borderDashOffset,
              lineJoin: dataset.borderJoinStyle,
              strokeStyle: dataset.borderColor,
              dataset: dataset,
              index: index,
            }));
          },
          marginRight: "100px",
          font: {
            size: 12.67, // Change the font size
          },
        },
        scales: {
          xAxis: {
            min: 0
          },
          yAxes: [{
            display: false
          }]
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle", // Set the point style to 'circle'
        radius: 4, // Set the size of the point elements
        backgroundColor: "blue", // Change the point color (if needed)
      },
    },
  };

  const chart = {
    line: <Line options={chartOptions} data={chartData2} style={{ height: "100%", width: "100%" }} />,
  };

  return (
    <div className="chart-container-custom">
      <div className="theme-chart">
        <div className="wrapper">{chart['line']}</div>
      </div>
    </div>
  );
};

export default LineChart;
