import React, { useState } from "react";
import { InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import { useSelector } from "react-redux";
import { useShareForumMutation } from "../../Data/services/CommunityApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast } from "../../Hooks/useToast";
import UserAvatarWithNameAndTag from "../CustomComponents/UserAvatarWithNameAndTag";

const ShareForumModal = ({ data = {}, handleModalClose }) => {
    const STRING = useLocalization()
    const [modalClass, setModalClass] = useState(
        "shareForumModalContainer scale-in-hor-center"
    );
    const user = useSelector(state => state.authInfo.user)
    const [sharePost] = useShareForumMutation();

    const [caption, setCaption] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    // const [mediaImage, setMediaImage] = useState('');

    const handleModalCloseWithAnimation = () => {
        setModalClass("shareForumModalContainer scale-out-horizontal");
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };

    const handleSubmit = async () => {
        if (isSubmitted) return;

        try {
            setIsSubmitted(true)
            const payload = {};
            payload.description = caption || '';
            const result = await sharePost({ slug: data.slug, data: payload }).unwrap();
            handleModalCloseWithAnimation();
        }
        catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            setIsSubmitted(false)
        }
    }
    return (
        <div>
            <ModalContainer handleClose={handleModalCloseWithAnimation}>
                <div className={modalClass}>
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <p className="fs-4 fw-medium m-0">{STRING.SharePost}</p>

                        <div className={"closeBtn"}>
                            <FaCircleXmark
                                fontSize={40}
                                color={"#F68B28"}
                                onClick={handleModalCloseWithAnimation}
                            />
                        </div>
                    </div>

                    <br></br>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <UserAvatarWithNameAndTag
                            userName={user.name || ''}
                            userTag={''}
                            avatarImage={user.image_url || ''}
                        />
                    </div>

                    <hr style={{ width: "100%" }}></hr>

                    <div className="d-flex flex-row justify-content-between w-100">
                        <UserAvatarWithNameAndTag
                            userName={data.user_name || ''}
                            userTag={''}
                            avatarImage={data.user_image}
                        />
                    </div>

                    <div className="d-flex flex-row justify-content-between w-100 mt-3 mb-1">
                        <p className='m-0 fw-bold '>{data.title}</p>
                    </div>
                    {!data?.media_url ? "" :
                        <div className="post-image my-2">
                            <img src={data?.media_url} />
                        </div>
                    }
                    <div className='d-flex flex-wrap w-100'>
                        <p className="mb-2 mt-1 fs-6 text-granite">
                            {data.description}
                        </p>
                    </div>
                    <hr style={{ width: "100%" }}></hr>
                    <InputGroup>
                        <Input
                            placeholder={STRING.WriteCaption2}
                            type={INPUT_TYPES.TEXT}
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </InputGroup>

                    <Button
                        classes="scale-in-center-slow fs-5 mt-1"
                        text={
                            <div className='d-flex align-items-center justify-content-center'>
                                {STRING.Share}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                            </div>
                        }
                        type={INPUT_TYPES.SUBMIT}
                        onClick={handleSubmit}
                    />
                </div>
            </ModalContainer>
        </div>
    );
};

export default ShareForumModal;
