import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../Constant/constants";
import { getToken } from "../../Data/services/localStorageService";
import _ from "lodash";

export default function PublicRoute({ children }) {
  const { isLoggedIn } = useSelector(state => state.authInfo);

  return (
    _.isUndefined(isLoggedIn) ? <></> :
      isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace={true} /> : children

  );
}
