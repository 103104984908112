import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { useGetMyFollowersQuery } from "../../Data/services/userApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { EmptyContainer } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import MemberCard from "./MemberCard";


const FollowerList = ({ handleModalClose = () => { } }) => {
  const STRING = useLocalization()

  const [modalClass, setModalClass] = useState(
    "addMemberInGroupModalContainer scale-in-hor-center"
  );

  const LIMIT = useMemo(() => 20, []);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(false)
  const [isFetchedFirstTime, setIsFetchedFirstTime] = useState(false)

  const { data, isFetching } = useGetMyFollowersQuery({ page: currentPage, limit: LIMIT, offset }, {
    skip: skip,
    refetchOnMountOrArgChange: false,
  });
  const [users, setUsers] = useState({
    currentPage: 0,
    data: [],
  })


  useEffect(() => {

    if (isFetching) return;
    setIsFetchedFirstTime(true);
    setSkip(true)

    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])


  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
      setSkip(false)
    }
  }, [inView])



  useEffect(() => {
    if (isFetching) return;

    if (data.links?.current === 1) {
      setUsers({
        currentPage: 1,
        data: data?.data
      })
    }
    else {
      setUsers(prev => {
        return {
          currentPage: data.links?.current,
          data: [...prev?.data, ...data?.data]
        }
      })
    }
  }, [data])





  const handleModalCloseWithAnimation = () => {
    setModalClass("addMemberInGroupModalContainer scale-out-horizontal h-100 overflow-hidden");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };


  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="h-100 w-100  d-flex flex-column">
            <div className="h-100 w-100 overflow-hidden">

              <div className="title-container d-flex align-items-center w-100 justify-content-between">
                <p className="fs-4 fw-medium m-0">{STRING.Followers}</p>

                <div className={"closeBtn"}>
                  <FaCircleXmark
                    fontSize={40}
                    color={"#F68B28"}
                    onClick={handleModalCloseWithAnimation}
                  />
                </div>
              </div>

              <br></br>
              {
                // <div className="d-flex flex-row justify-content-start w-100 my-2 mb-4">
                //   <p className="counter">{selectedMembers.length} Members </p>
                // </div>
              }
              <div className="users-container">
                <div className="chat-container">

                  {
                    (_.isEmpty(users?.data) && (users?.currentPage > 0)) ? <EmptyContainer text={STRING.FollowersNotFound} /> :
                      (users?.data ?? []).map((member, index) => (
                        <>
                          {index > 0 ? <hr></hr> : ""}
                          <div
                            key={index}
                            ref={
                              (users?.data.length >= (total - offset)) ? null :
                                (index === (users?.data.length - 1)) ? ref : null}
                          >
                            <MemberCard
                              key={index}
                              isSelected={false}
                              data={member}
                            />
                          </div >
                        </>
                      ))
                  }
                  {(isFetching || (users?.currentPage === 0)) ? <div className="w-100 d-flex justify-content-center pt-4 pb-3 text-light"><Spinner color="white" /></div> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer >
    </div >
  );
};

export default FollowerList;
