import React from 'react'
import { Description } from '../../../Components/CustomComponents'
import { useLocalization } from '../../../Hooks/useLocalization'

const SignUpLink = ({ handleSignUpClick }) => {
    const STRING = useLocalization()
    return (

        <div
            className='d-flex justify-content-center flex-direction-row mt-1 mb-4 tracking-in-expand-fwd-bottom'
        >
            <Description
                classes="fs-6"
                text={STRING.NotAMember}
            />
            <Description
                classes="link-text fs-6 ps-1 fw-bold "
                text={STRING.SignUpNow}
                onClick={handleSignUpClick}
            />
        </div>

    )
}

export default SignUpLink
