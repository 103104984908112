import React from 'react'
import { useLocalization } from '../../../Hooks/useLocalization'

const SetNewPasswordInstructions = () => {
    const STRING = useLocalization()
    return (
        <div className='w-100'>


            <ul className="w-100 m-0 fw-light fs-7">
                <li className='tracking-in-expand-fwd-bottom'> {STRING.InstuctionsForNewPassowrd.PointOne} </li>
                <li className='tracking-in-expand-fwd-bottom-slow'> {STRING.InstuctionsForNewPassowrd.PointTwo} </li>
            </ul>
        </div>
    )
}

export default SetNewPasswordInstructions
