import React from 'react'

const RoundedCheckBox = ({ value = false, handleChange = () => { } }) => {
    return (
        <div>
            <div class="checkbox-wrapper-22" >
                <label class="switch" for="checkbox">
                    <input type="checkbox" id="checkbox" checked={value} onChange={handleChange} />
                    <div class="slider round"></div>
                </label>
            </div>
        </div>
    )
}

export default RoundedCheckBox
