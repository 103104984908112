import moment from "moment";
import React, { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { GET_TRAINING_LOG_PAYLOAD_FORMAT, LOG_TYPE_ENUM } from "../../../Constant/constants";
import { useGetTrainingLogQuery } from "../../../Data/services/trainingApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import _ from "lodash";
import LineChart from "../../../Components/Chart/LineChart";

const MonthlyCalendar = ({ children }) => {
  const STRING = useLocalization()

  const [isViewAnalytics, setViewAnalytics] = useState(false);
  const [month, setMonth] = useState(moment().startOf('month'));

  const { data, isFetching } = useGetTrainingLogQuery({ type: LOG_TYPE_ENUM.MONTH, date: moment(month).format(GET_TRAINING_LOG_PAYLOAD_FORMAT) }, {
    refetchOnMountOrArgChange: true
  })

  // Helper function to get the number of days in a month
  const getDaysInMonth = (currentYear, currentMonth) => {
    new Date(currentYear, currentMonth + 1, 0).getDate();
  }


  const days = useMemo(() => {

    const currentMonth = moment(month).month()
    const currentYear = moment(month).year()

    // Get the first day of the month
    let firstDay = new Date(currentYear, currentMonth, 1).getDay();


    // Adjust so that the week starts on Monday
    firstDay = firstDay === 0 ? 6 : firstDay - 1;

    // Get the number of days in the current month
    const daysInMonth = moment(month).endOf('month').date()

    // Get the number of days in the previous month
    const daysInPrevMonth = moment(moment(month).subtract(1, 'months')).endOf('month').date();

    // Create an array to hold all the days to display in the calendar
    const days = [];

    // Fill in the days from the previous month
    for (let i = firstDay - 1; i >= 0; i--) {
      days.push({
        day: daysInPrevMonth - i,
        currentMonth: false,
      });
    }

    // Fill in the days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        day: i,
        currentMonth: true,
      });
    }

    // Fill in the days from the next month
    const nextMonthDays = 35 - days.length; // Total of 42 cells for a 6-week calendar
    for (let i = 1; i <= nextMonthDays; i++) {
      days.push({
        day: i,
        currentMonth: false,
      });
    }

    return days;
  }, [month])


  const handlePrevMonth = () => {
    setMonth(prev => moment(prev).subtract(1, 'months'))
  }

  const handleNextMonth = () => {
    setMonth(prev => moment(prev).add(1, 'months'))
  }

  const handleViewAnalytics = () => {
    setViewAnalytics(true)
  }

  const handleViewGrid = () => {
    setViewAnalytics(false)
  }


  return (
    <>
      <div className=" d-flex flex-row flex-wrap justify-content-center  align-items-center justify-content-sm-between">
        <div className="left-side p-sm-0 px-5">
          {`${STRING.MonthNames[moment(month).month()]}`}
        </div>
        <div className="right-side d-flex flex-row">
          {_.isEmpty(data?.data) ? "" :
            isViewAnalytics ?

              <button className="viewChart" onClick={handleViewGrid}>
                View Grid
              </button> :
              <button className="viewChart" onClick={handleViewAnalytics}>
                View Analytics
              </button>
          }
          <div className="align-items-center d-flex flex-row justify-content-between text-center mx-2">
            <span className="left-arrow cursor-pointer" onClick={handlePrevMonth}>
              <MdKeyboardArrowLeft />
            </span>
            <span className="d-flex flex-column center-text">
              {`${STRING.MonthNames[moment(month).month()]}`}
              <span className="w-100  fs-7 mx-auto">
                {moment(month).year()}
              </span>
            </span>
            <span className="right-arrow cursor-pointer" onClick={handleNextMonth}>
              <MdKeyboardArrowRight />
            </span>
          </div>
          {children}
        </div>
      </div>
      <div className="focus-in-expand mt-3">
        {
          isViewAnalytics ?
            isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> :
              _.isEmpty(data?.data) ? "" :
                <LineChart type="month" data={data?.data} data_value={month} /> :
            <div className="monthly-view">
              <div className="day-names">
                {STRING.MonthStart.map((day, index) => (
                  <div key={index} className="day-name">
                    {day}
                  </div>
                ))}
              </div>

              <div className="days">
                {days.map((day, index) => (
                  <div
                    key={index}
                    className={`day ${!day.currentMonth ? "other-month" : "current-month"} ${(data?.data?.days ?? [])?.includes(day.day) ? "active-day cursor-pointer" : ""}`}
                  >
                    {!day.currentMonth ? day.day :
                      isFetching ? <Spinner size="sm" /> : day.day}
                  </div>
                ))}
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default MonthlyCalendar;
