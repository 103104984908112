import { message, Upload } from 'antd'; // import { FaPlusCircle } from "react-icons/fa";
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Col, InputGroup, Row, Spinner } from 'react-bootstrap';
import { FaCircleXmark } from 'react-icons/fa6';
import { IMAGES } from '../../Constant/ImageConstant';
import { INPUT_TYPES } from '../../Constant/InputTypesAndPatternsConstant';
import { TOAST_MESSAGE } from '../../Constant/Message';
import { useUpdateWorkOutMutation } from '../../Data/services/dashboardApi';
import { useLocalization } from '../../Hooks/useLocalization';
import Toast, { ErrorToast, SuccessToast } from '../../Hooks/useToast';
import Chips from '../CommonComponents/Chip';
import { Button, Checkbox, Input } from '../CustomComponents';
import ModalContainer from '../ModalContainer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { baseRoute } from '../../Helper';
import { WEB_ROUTES } from '../../Constant/constants';


const { Dragger } = Upload;

const EditWorkoutModal = ({ data = {}, handleModalClose }) => {
    const STRING = useLocalization()
    const navigate = useNavigate()
    const { is_connectAccountConnected, stripe_capabilities } = useSelector((state) => state.authInfo.user);

    const [updateWorkout] = useUpdateWorkOutMutation();

    const INVALID_NUMBER = useMemo(() => [
        "-",
        "+",
        "e",
        "E",
    ], []);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [modalClass, setModalClass] = useState('addWorkoutModalContainer scale-in-hor-center');
    const [priceModal, setPriceModal] = useState(false);

    const [selectedValue, setSelectedValue] = useState(data?.is_paid ? "paid" : "free");
    const [mediaImage, setMediaImage] = useState(data?.image_url || '');

    const [tags, setTags] = useState(data?.tags ? data.tags : []);
    const [workoutTag, setWorkoutTag] = useState('');

    const [workoutTitle, setWorkoutTitle] = useState(data?.title || '');
    const [workoutDescription, setWorkoutDescription] = useState(data?.description || '');
    const [exercises, setExercises] = useState(
        data?.exercise.map(item => {
            return {
                slug: item.slug,
                name: item.title,
                details: item.details,
            }
        }) ||
        [{ name: '', details: '' }]
    );
    const [deletedExercise, setDeletedExercise] = useState([]);

    const [workoutPrice, setWorkoutPrice] = useState(data?.is_paid ? data?.price : undefined);

    const handleAddExercise = () => {
        setExercises([...exercises, { name: '', details: '' }]);
    };

    const handleExerciseChange = (index, e) => {
        const { name, value } = e.target;

        const newExercises = _.cloneDeep(exercises);
        newExercises[index][name] = value;
        setExercises(newExercises);
    };


    const handleKeyDown = (event) => {
        // console.log('kenDown')
        // console.log(event.key)

        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            if (workoutTag.trim()) {
                // setTags([...tags, workoutTag.trim()]);
                setTags(_.concat(tags, workoutTag.trim()));
                setWorkoutTag('');
            }
        }
    };

    const handleRemoveChip = (index) => {
        // setTags(tags.filter((chip, chipIndex) => chipIndex !== index));
        const updatedChips = _.without(tags, tags[index]);
        setTags(updatedChips);
    };

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (selectedValue === value) {
            setSelectedValue(null);
        } else {
            setSelectedValue(value);
        }
    };

    const handleModalCloseWithAnimation = () => {
        setModalClass('addWorkoutModalContainer scale-out-horizontal');
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };

    // MARK: UPLOAD IMAGE 

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            ErrorToast(STRING.FileFormatRequired, 'error', false);
        }
        else if (!isLt2M) {
            ErrorToast(STRING.ImageSizeRequired, 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    // upload files
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: "image/jpeg,image/png,image/png",
        value: !_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage),
        beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
        // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
        customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
        // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
                setMediaImage(info.file.originFileObj);

            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            setMediaImage('');
        },
    };

    const handleNextSection = () => {
        if (_.isEmpty(mediaImage)) return ErrorToast('Workout image should not be empty')
        if (_.isEmpty(workoutTitle)) return ErrorToast('Workout title should not be empty')
        if (_.isEmpty(workoutDescription)) return ErrorToast('Workout description should not be empty')
        if (_.isEmpty(tags)) return ErrorToast('Workout tags should not be empty')
        if (exercises.some(item => (!item.name || !item.details))) return ErrorToast('You forgot to give exercise title or description')

        setPriceModal(true)
    }


    const handleStripeConnect = () => {
        navigate(baseRoute(WEB_ROUTES.PAYMENT))
        handleModalCloseWithAnimation()
    }


    const handleSubmit = async () => {
        if (isSubmitted) return;

        if ((selectedValue !== 'free') && !parseFloat(workoutPrice)) return ErrorToast('Workout price should be greater than 0')
        try {
            setIsSubmitted(true);
            const formData = new FormData();
            if (_.isObject(mediaImage)) {
                formData.append('image_url', mediaImage)
            }
            formData.append('title', workoutTitle)
            formData.append('description', workoutDescription)

            if (selectedValue === 'free') {
                formData.append('is_paid', '0')
                formData.append('price', 0)
            } else {
                formData.append('is_paid', '1')
                formData.append('price', parseFloat(workoutPrice))
            }

            for (let i = 0; i < tags.length; i++) {
                formData.append(`tags[${i}]`, tags[i])
            }

            let j = 0;
            for (let i = 0; i < exercises.length; i++) {
                // if (deletedExercise.includes(exercises[i]?.slug)) continue;
                formData.append(`exercise[${j}][slug]`, exercises[i]?.slug || '')
                formData.append(`exercise[${j}][title]`, exercises[i].name)
                formData.append(`exercise[${j}][details]`, exercises[i].details)
                j++;
            }

            for (let i = 0; i < deletedExercise.length; i++) {
                formData.append(`deleted_exercise[${i}]`, deletedExercise[i])
            }

            const response = await updateWorkout({ slug: data?.slug, data: formData });

            if (response.error)
                if (response.error.status === 400) {
                    const message = await response.error.data.message;
                    Toast(`${message}`, "error");
                }
                else {
                    throw new Error('SERVER_ERROR');
                }

            if (!response.error && response.data.code === 200) {
                SuccessToast(TOAST_MESSAGE.SUCCESS.WORKOUT_UPDATED);
                handleModalCloseWithAnimation()
            }
        } catch (error) {
            if (error?.data && error?.data?.message) {
                Toast(error?.data?.message, "error");
            } else {
                Toast(TOAST_MESSAGE.ERROR.WORKOUT_UPDATED, "error");
            }
        }
        finally {
            setIsSubmitted(false)
        }
    }

    const handleRemoveExercise = (index, slug = '') => {
        if (exercises.length <= 1) return;

        if (slug) setDeletedExercise(prev => ([...prev, slug]))

        setExercises(prev => prev.filter((item, ind) => ind !== index))

    }


    return (
        <div>
            {
                !priceModal ?

                    <ModalContainer
                        handleClose={handleModalCloseWithAnimation}
                    >
                        <div className={modalClass}>
                            {
                                // <div className='d-flex align-items-center w-100 justify-content-end'>
                                // <p className='fs-4 fw-medium m-0'
                                //         Notification
                                //     </p>
                                // </div>
                            }
                            <div className={'closeBtn'}>
                                <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalCloseWithAnimation} />
                            </div>

                            <br></br>

                            <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { }}>
                                <div className=''>
                                    <p className='fs-4 fw-semibold m-0'>{STRING.UploadPhoto}</p>
                                </div>
                            </div>
                            {
                                // MARK: DRAGGER 
                            }
                            <Dragger  {...props} className='w-100' >

                                {!_.isEmpty(mediaImage) ?
                                    <img
                                        src={!_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage)}
                                        alt="avatar"
                                        style={{ width: '100%', height: '100px' }}
                                        className="img-fluid"
                                    /> :
                                    <div>
                                        <p className="ant-upload-drag-icon">
                                            <img src={IMAGES.UPLOAD_ICON} alt="" />
                                        </p>
                                        <p className="ant-upload-text">Drop your image here, or <span>browse</span></p>
                                        <p className="ant-upload-hint"> Supports: PNG, JPG, JPEG, WEBP </p>
                                    </div>
                                }

                            </Dragger>

                            <hr></hr>

                            <InputGroup>
                                <Input
                                    placeholder={STRING.WorkoutTitle}
                                    type={INPUT_TYPES.TEXT}
                                    value={workoutTitle}
                                    onChange={(e) => { setWorkoutTitle(e.target.value) }}
                                // onBlur={onBlur}
                                />
                                {
                                    // <div className="input-before-icon" >
                                    //     <CiUser />
                                    // </div>
                                }
                            </InputGroup>

                            <InputGroup>
                                <Input
                                    placeholder={STRING.WorkoutDescription}
                                    type={INPUT_TYPES.TEXTAREA}
                                    rows={3}
                                    as={INPUT_TYPES.TEXTAREA}
                                    value={workoutDescription}
                                    onChange={(e) => setWorkoutDescription(e.target.value)}
                                // onBlur={onBlur}
                                />
                                {
                                    // <div className="input-before-icon" >
                                    //     <CiUser />
                                    // </div>
                                }
                            </InputGroup>

                            <InputGroup>
                                <Input
                                    placeholder={STRING.WorkoutTag}
                                    type={INPUT_TYPES.TEXT}
                                    value={workoutTag}
                                    onChange={(e) => setWorkoutTag(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                // onBlur={onBlur}
                                />
                                {
                                    // <div className="input-before-icon" >
                                    //     <CiUser />
                                    // </div>
                                }
                            </InputGroup>



                            <div className='d-flex flex-wrap w-100 '>
                                {
                                    tags.map((chips, index) => <Chips text={chips} index={index} handleRemoveChip={handleRemoveChip} />)
                                }
                            </div>

                            <hr></hr>


                            <p className='m-0 fw-bold w-100 fs-5'> {STRING.Exercises} </p>



                            {
                                exercises.map((exercise, index) => (

                                    <div className='exerciseSection'>
                                        {(exercises.length === 1) ? "" :
                                            <div className='removeContainer' onClick={() => handleRemoveExercise(index, exercise?.slug)}>
                                                <span>{STRING.Remove}</span>
                                            </div>
                                        }
                                        <div className='exerciseNumber'>
                                            <span>{STRING.Exercise} {index + 1}</span>
                                        </div>

                                        <InputGroup>
                                            <Input
                                                placeholder={STRING.WorkoutTitle}
                                                type={INPUT_TYPES.TEXT}
                                                name="name"
                                                value={exercise.name}
                                                onChange={(e) => { handleExerciseChange(index, e) }}
                                            // onBlur={onBlur}
                                            />
                                        </InputGroup>

                                        <InputGroup>
                                            <Input
                                                placeholder={STRING.WorkoutDescription}
                                                type={INPUT_TYPES.TEXTAREA}
                                                rows={3}
                                                as={INPUT_TYPES.TEXTAREA}
                                                name="details"
                                                value={exercise.details}
                                                onChange={(e) => { handleExerciseChange(index, e) }}
                                            // onBlur={onBlur}
                                            />
                                        </InputGroup>
                                    </div>

                                ))
                            }

                            <Row className='w-100'>
                                <Col sm={12} md={6}>
                                    <Button
                                        classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-5 exerciseBtn "
                                        text={STRING.AddNewExerciseButton}
                                        type={INPUT_TYPES.SUBMIT}
                                        onClick={handleAddExercise}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Button
                                        classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-5"
                                        text={STRING.Next}
                                        type={INPUT_TYPES.SUBMIT}
                                        onClick={handleNextSection}
                                    />
                                </Col>
                            </Row>

                        </div>
                    </ModalContainer>

                    :
                    <div className='scale-in-hor-center'>

                        <ModalContainer
                            handleClose={handleModalCloseWithAnimation}
                        >
                            <div className={modalClass}>
                                <div className={'closeBtn'}>
                                    <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalCloseWithAnimation} />
                                </div>
                                <div className='d-flex align-items-center w-100 justify-content-start' >
                                    <div className=''>
                                        <p className='fs-4 fw-semibold m-0'>{STRING.AddPricing}</p>
                                        <p className='fs-7 m-0 description'>{STRING.PricingInstruction}</p>
                                    </div>
                                </div>
                                <div className={`d-flex flex-row justify-content-start checkBoxes w-100 py-3`}>
                                    <Checkbox
                                        classes=""
                                        label={STRING.SetFree}
                                        value='free'
                                        checked={selectedValue === 'free'}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                                {(selectedValue === 'free') ? "" :
                                    !is_connectAccountConnected ?
                                        <div className="d-flex align-items-center w-100 justify-content-start">
                                            <div className="">
                                                <p className="fs-5 m-0 fs-5 fw-bold label">{STRING.StripeConnect}</p>
                                                <p className="fs-8 m-0 description">
                                                    {STRING.StripeConnectDescription(handleStripeConnect)}
                                                </p>
                                            </div>
                                        </div> :
                                        !stripe_capabilities ?
                                            <div className="d-flex align-items-center w-100 justify-content-start">
                                                <div className="">
                                                    <p className="fs-5 m-0 fs-5 fw-bold label">{STRING.IncorrentConnectDetails}</p>
                                                    <p className="fs-8 m-0 description">
                                                        {STRING.IncorrentConnectDetailsDescription(handleStripeConnect)}
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className='d-flex align-items-center w-100 justify-content-start' >
                                                    <div className=''>
                                                        <p className='fs-6 fw-bold m-0 label'>{STRING.SetPricing}</p>
                                                    </div>
                                                </div>
                                                <InputGroup>
                                                    <Input
                                                        placeholder={STRING.PricePlaceholder}
                                                        type={INPUT_TYPES.NUMBER}
                                                        value={workoutPrice}
                                                        onKeyDown={(e) => (INVALID_NUMBER.includes(e.key) && e.preventDefault())}
                                                        onChange={(e) => setWorkoutPrice(e.target.value)}
                                                    // onBlur={onBlur}
                                                    />
                                                </InputGroup>
                                            </>
                                }
                                <br></br>
                                <Button
                                    classes="scale-in-center-slow fs-5"
                                    text={
                                        <div className='d-flex align-items-center justify-content-center'>
                                            {STRING.PostWorkoutButton}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                                        </div>
                                    }
                                    onClick={handleSubmit}
                                    type={INPUT_TYPES.SUBMIT}
                                />
                            </div>
                        </ModalContainer>
                    </div>
            }


        </div >
    )

}

export default EditWorkoutModal