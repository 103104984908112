import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { IMAGES } from "../../Constant/ImageConstant";
import { useGetTemplateDetailsQuery } from "../../Data/services/dashboardApi";
import { EmptyContainer } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import { useLocalization } from "../../Hooks/useLocalization";

const FreeTemplateViewModal = ({ data = {}, handleModalClose }) => {
  const STRING = useLocalization()
  const [modalClass, setModalClass] = useState(
    "freeTemplateViewModalContainer scale-in-hor-center"
  );


  const { data: details, isFetching } = useGetTemplateDetailsQuery(data.slug);
  const templateData = useMemo(() => {
    if (_.isEmpty(details) && _.isEmpty(data)) return {};
    return (
      {
        ...(data ? data : {}),
        ...(details?.data ? details.data : {})
      }
    )

  }, [details])


  const handleModalCloseWithAnimation = () => {
    setModalClass("freeTemplateViewModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            {/* <p className='fs-4 fw-medium m-0'>
                            {STRING.CreateCommunityForm}
                        </p> */}

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mb-4">
            <img
              src={IMAGES.LOGO}
              alt="Better Work Logo "
              style={{ width: "50%" }}
            />
          </div>

          {!templateData?.image_url ? "" :
            <div className="templateImage mb-4">
              <img
                src={templateData?.image_url}
                alt="Better Work Logo "
              />
            </div>
          }

          <h3>{templateData.name}</h3>

          <table
            style={{
              width: "50%",
              padding: "6px",
              borderRadius: "20px",
              backgroundColor: "#26262E",
            }}
          >
            <tr style={{ width: "100%" }}>
              <td style={{ width: "30%", textAlign: "center" }}>
                <br></br>
                <h4 style={{ fontSize: "28px", fontWeight: "bold" }}> {templateData.duration}</h4>
                <p>mins</p>
              </td>

              {/* <td style={{ width: "50%", textAlign: "center" }}>
                <br></br>
                <h4 style={{ fontSize: "28px", fontWeight: "bold" }}> {templateData.calories} </h4>
                <p>Kcal</p>
              </td> */}
            </tr>
          </table>
          <p className="text-center m-0 mt-3">
            {templateData.description}
          </p>

          <br></br>


          {_.isEmpty(templateData?.section) ?
            (isFetching ? <div className="w-100 text-light py-3 d-flex justify-content-center"><Spinner size="lg" /></div> :
              <EmptyContainer text={STRING.TemplateSectionsNotFound} classes="w-100 py-3 text-white" />
            ) :

            <div class="content-data mb-5">
              {(templateData.section ?? [])?.map((item) => {
                return (
                  <div class="section-container my-3" index={item.slug}>
                    <h3 class="fw-bold mb-3"> {item.title} </h3>
                    {(item.exercise ?? [])?.map(exercise => {
                      return (
                        <div class="exercise-container ps-1" key={exercise.slug}>
                          <table style={{ width: "100%", marginTop: "8px" }}>
                            <tr style={{ width: "100%" }}>
                              <td style={{ width: "fit-content" }}>
                                <h5 class="fst-normal align-items m-0">
                                  {exercise.name}
                                  <span
                                    class="badge ms-3 fs-6 fw-normal"
                                    style={{ backgroundColor: "#74C772" }}
                                  >
                                    {exercise.intensity}
                                  </span>
                                </h5>
                              </td>
                            </tr>
                          </table>

                          <table style={{ width: "100%", marginTop: "8px" }}>
                            <tr style={{ width: "100%" }}>
                              <td style={{ width: "50%" }}>
                                <h5 class="fst-normal align-items m-0">Standard Time</h5>
                              </td>
                              <td style={{ width: "50%", textAlign: "right" }}>
                                <span class="ms-auto  align-items-center">
                                  <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA1CAYAAAA6XQlSAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOkSURBVHgB7VrRcdswDIUVJ79JJqg7QbxB3QnqDZpukE5Qd4K2E7idIMkEUSaIN5A2cP5yZ8t2H2w6lWBIlARa19713fFiWxD5CIIACIbIiMVicZ1l2Rxt42kJ2kcyokdGrFarZLPZDGqKP/f7/UsyICIjQPaigfgzGWEmvF6vv9YUfe71et/JCLNJSLC95r/DBIKOYdZw1/hP+NjokwHsIWCzwyiKrvB5iE01xN+CDJ4/0G7DzbBBH09PT2MyoNWGWC6XIxDgIDBGa+LWGOza7jCxX1byXjBR1liNqFa3lUY/HgtB6Qsi6ZCagiNZYKKyTV9eXgb78Vy4f33O5PfPvCaBF3jZp1S+9LzEMUwkhi2n+DyjP1q6xLKfw3bZtkf4aVTRD787gWwP/UzzD9D35OTkxB+gMOCkQisPIPGuYWhmBVy3WC1/0lRGFnb1xETJCO6DEycfWbZjb2cVZL9RYDAhE1m3ZPLleRYgl60Yc5zpOXX1mM4byBfnrVxLQ/AY2th573EACNwqsxxTR3CaPtjcZcLXrWwoMHifSB55P5wXTIRgQkcABwU3VqIS2XF5qtSyC7mFWYVwXRpZaaOanManMDnMaFrLbsKSLSXMUILLjpNLEaV2P1BAlJCt3COKlufbqKo8CGq7bcjuId0cc0WeEUltzigQmKxMZBh1kxnI3eW/M9eITwpCKKYAsJJ1KChvW7CRLiSEd7CYgehneGCu0k6AN2RAKLIMlyoUvYriIZqe0QrQUsa2EVMhvAl+zJcTbmizXjBhWaA7JwNytTY+2t+EJLuFXMIQqSRr2WpaDC1GsFsruA4uipAR0Cxr115aPeSSRug4FT+O6O+BjBEz1vCjEOosYa+BUf4LNB6ryU9ZntolSlLei8jZWpwXxm+dnzQkoE15AI1f94U3Ye4YWsDI5AlaiVDBk/i6UI5IhylvphxC4ZNvqGPwmF7t7qEcSzZd1CRyZIcK2cT3gixmJJXFjEBgu9USJ+/YJUuSHFPT3Lcpy9OKGceyaaegeWuye+ClnxpptNsQJlJV1eeyA7VBmaZdm7bx1c7n35b16yvpeq8MeGmgjUmFSEq7SHmPXDjFFUG6j0gcSkFwgKjFV2KceY2rbv5d/vyDLITdwAOQeWjwbwZNEaP/z2dnZ94SQ60jEqegmPlbfPykpKMWbE8luEB/X4dsa2TtLlYKYb9tOcz0rwHO2fOt6JUryHCTh9CU3NUYbJm1eB/iNPLP4Dcv5LzO882EvAAAAABJRU5ErkJggg=="
                                    width="16"
                                    height="18"
                                    class="me-2"
                                  />{" "}
                                  {exercise.standard_time}
                                </span>
                              </td>
                            </tr>
                          </table>

                          <table style={{ width: "100%", marginTop: "8px" }}>
                            <tr style={{ width: "100%" }}>
                              <td style={{ width: "50%" }}>
                                <h5 class="fst-normal align-items m-0">Goal Time</h5>
                              </td>
                              <td style={{ width: "50%", textAlign: "right" }}>
                                <span class="ms-auto  align-items-center">
                                  <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAA1CAYAAAA6XQlSAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOkSURBVHgB7VrRcdswDIUVJ79JJqg7QbxB3QnqDZpukE5Qd4K2E7idIMkEUSaIN5A2cP5yZ8t2H2w6lWBIlARa19713fFiWxD5CIIACIbIiMVicZ1l2Rxt42kJ2kcyokdGrFarZLPZDGqKP/f7/UsyICIjQPaigfgzGWEmvF6vv9YUfe71et/JCLNJSLC95r/DBIKOYdZw1/hP+NjokwHsIWCzwyiKrvB5iE01xN+CDJ4/0G7DzbBBH09PT2MyoNWGWC6XIxDgIDBGa+LWGOza7jCxX1byXjBR1liNqFa3lUY/HgtB6Qsi6ZCagiNZYKKyTV9eXgb78Vy4f33O5PfPvCaBF3jZp1S+9LzEMUwkhi2n+DyjP1q6xLKfw3bZtkf4aVTRD787gWwP/UzzD9D35OTkxB+gMOCkQisPIPGuYWhmBVy3WC1/0lRGFnb1xETJCO6DEycfWbZjb2cVZL9RYDAhE1m3ZPLleRYgl60Yc5zpOXX1mM4byBfnrVxLQ/AY2th573EACNwqsxxTR3CaPtjcZcLXrWwoMHifSB55P5wXTIRgQkcABwU3VqIS2XF5qtSyC7mFWYVwXRpZaaOanManMDnMaFrLbsKSLSXMUILLjpNLEaV2P1BAlJCt3COKlufbqKo8CGq7bcjuId0cc0WeEUltzigQmKxMZBh1kxnI3eW/M9eITwpCKKYAsJJ1KChvW7CRLiSEd7CYgehneGCu0k6AN2RAKLIMlyoUvYriIZqe0QrQUsa2EVMhvAl+zJcTbmizXjBhWaA7JwNytTY+2t+EJLuFXMIQqSRr2WpaDC1GsFsruA4uipAR0Cxr115aPeSSRug4FT+O6O+BjBEz1vCjEOosYa+BUf4LNB6ryU9ZntolSlLei8jZWpwXxm+dnzQkoE15AI1f94U3Ye4YWsDI5AlaiVDBk/i6UI5IhylvphxC4ZNvqGPwmF7t7qEcSzZd1CRyZIcK2cT3gixmJJXFjEBgu9USJ+/YJUuSHFPT3Lcpy9OKGceyaaegeWuye+ClnxpptNsQJlJV1eeyA7VBmaZdm7bx1c7n35b16yvpeq8MeGmgjUmFSEq7SHmPXDjFFUG6j0gcSkFwgKjFV2KceY2rbv5d/vyDLITdwAOQeWjwbwZNEaP/z2dnZ94SQ60jEqegmPlbfPykpKMWbE8luEB/X4dsa2TtLlYKYb9tOcz0rwHO2fOt6JUryHCTh9CU3NUYbJm1eB/iNPLP4Dcv5LzO882EvAAAAABJRU5ErkJggg=="
                                    width="16"
                                    height="18"
                                    class="me-2"
                                  />{" "}
                                  {exercise.goal_time}
                                </span>
                              </td>
                            </tr>
                          </table>

                          {!exercise?.notes ? "" :
                            <table style={{ width: "100%", marginTop: "8px" }}>
                              <tr style={{ width: "100%" }}>
                                <td style={{ width: "15%" }}>
                                  <h5 class="fst-normal align-items m-0"> Note :</h5>
                                </td>
                                <td style={{ width: "85%", textAlign: "right" }}>
                                  <span class="fst-italic ms-2">
                                    {exercise.notes}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          }

                          <div class="extra-after"></div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          }
        </div>
      </ModalContainer>
    </div>
  );
};

export default FreeTemplateViewModal;
