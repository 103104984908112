import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TagPlaceholder } from "../../Components/CustomComponents";
import FollowerList from "../../Components/Modals/FollowerList";
import FollowingList from "../../Components/Modals/FollowingList";
import { useGetProfileQuery } from "../../Data/services/userApi";
import { useLocalization } from "../../Hooks/useLocalization";
import CustomTabs from "./Components/CustomTabs";
import MyPost from "./Components/MyPost";
import MyPurchase from "./Components/MyPurchase";
import MyTemplate from "./Components/MyTemplate";
import MyWorkout from "./Components/MyWorkout";
import SharedPost from "./Components/SharedPost";
import styles from "./styles/index.module.css";

const Profile = ({ prev_route = null, setPage }) => {
  const STRING = useLocalization()
  const { state } = useLocation();
  const [tableView, setTableView] = useState(state?.tab || prev_route || STRING["MyPosts"]);
  const user = useSelector(state => state.authInfo.user)
  const { data, isLoading, isFetching } = useGetProfileQuery();

  const userData = useMemo(() => {
    return {
      ...user,
      ...(data?.data ?? {})
    }
  }, [data])

  const [isShowFollower, setIsShowFollower] = useState(false)
  const [isShowFollowing, setIsShowFollowing] = useState(false)

  const tabOptions = [
    STRING["MyPosts"],
    STRING["MyWorkout"],
    STRING["MyPurchase"],
    STRING["SharedPosts"],
    STRING["MyTemplate"],
  ];

  const ComponentView = {
    [STRING["MyPosts"]]: <MyPost setPage={setPage} />,
    [STRING["MyWorkout"]]: <MyWorkout setPage={setPage} />,
    [STRING["MyPurchase"]]: <MyPurchase setPage={setPage} />,
    [STRING["SharedPosts"]]: <SharedPost setPage={setPage} />,
    [STRING["MyTemplate"]]: <MyTemplate setPage={setPage} />,
  };

  return (
    <div className={styles.Profile}>
      {!isShowFollower ? "" : <FollowerList handleModalClose={() => setIsShowFollower(false)} />}
      {!isShowFollowing ? "" : <FollowingList handleModalClose={() => setIsShowFollowing(false)} />}
      <Row className={styles.profile_header}>
        <Col sm={12} md={6} className="text-white">
          <div className="w-100 d-flex flex-column">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-start h-100 gap-3 slide-in-right ">
              <div>
                <img
                  src={userData.image_url}
                  alt="User Profile"
                  className={styles.profile_image}
                />
              </div>
              <div>
                <div className={styles.profile_name}>{userData.name}</div>
                <div className={styles.profile_description}>
                  {user.email}
                </div>
              </div>
            </div>
            {(_.isEmpty(userData.sports_type) && isFetching) ? <TagPlaceholder length={7} classes="mt-2 py-3" tagClasses="tag-animate" /> :
              <div className={`mt-1 py-3 ${styles.userTagsContainer}`}>
                {(userData.sports_type ?? []).map((item, index) => {
                  return <span key={index} className={styles.tag}>{item}</span>
                })}
              </div>
            }
          </div>
        </Col>
        <Col
          sm={12}
          md={6}
          className={`text-white my-3 ${styles.count_follow_style}`}
        >
          <div className={`h-100 ${styles.gap_row} slide-in-left`}>
            <div className="d-flex flex-column justify-items-center align-items-center">
              {isLoading ? <span className="mb-2">
                <Spinner />
              </span> :
                <div className={styles.following_followers_count} onClick={() => setIsShowFollower(true)}>{userData.followers || 0}</div>
              }
              <div className={styles.following_followers_text}>{STRING.Follower}</div>
            </div>
            <div className="d-flex flex-column justify-items-center align-items-center">
              {isLoading ? <span className="mb-2">
                <Spinner />
              </span> :
                <div className={styles.following_followers_count} onClick={() => setIsShowFollowing(true)}>{userData.followings || 0}</div>
              }
              <div className={styles.following_followers_text}>{STRING.Following}</div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12}>
          <div className={`my-4 ${styles.tabs_style}`}>
            <CustomTabs optionList={tabOptions} tableView={tableView} setTableView={setTableView} />
          </div>
        </Col>

        <Col sm={12} md={4} />
      </Row>

      <Row className="text-white">
        <Col xs={12}>{ComponentView[tableView]}</Col>
      </Row>
    </div>
  );
};

export default Profile;
