import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, SubTitle } from "../../../Components/CustomComponents";
import { useConnectAccountMutation } from "../../../Data/services/userApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import { ErrorToast } from "../../../Hooks/useToast";


export default function ConnectAccount() {
    const STRING = useLocalization()
    const navigate = useNavigate()
    const { is_connectAccountConnected, stripe_capabilities } = useSelector(state => state.authInfo.user);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [createConnectAccount] = useConnectAccountMutation();

    const connectAccount = async () => {
        if (isSubmitted) return;

        setIsSubmitted(true)
        try {
            const response = await createConnectAccount().unwrap()
            window.location.href = response?.data?.account_link?.url;
        }
        catch (err) {
            console.log(err);
            ErrorToast('Error in linking connect account.')
            setIsSubmitted(false)
        }



    }
    return (
        <div className="d-flex flex-row align-items-center mt-2 mb-5">
            <SubTitle text={STRING.ConnectAccount} />
            {is_connectAccountConnected ?
                stripe_capabilities ?
                    <Button
                        text={<div className="d-flex flex-row align-items-center">Linked<span className="d-flex ms-2 color-success "><FaCheck /></span></div>}
                        classes="ms-auto w-fit-content fs-5 px-5 py-1"
                    /> :
                    <Button
                        text={
                            <div className="d-flex flex-row align-items-center">
                                {STRING.IncorrentConnectDetails}
                                {
                                    !isSubmitted ?
                                        <span className="d-flex ms-3 color-error "><FaInfoCircle /></span> :
                                        <span className="d-flex ms-3"><Spinner size="sm" /></span>
                                }
                            </div>}
                        classes="ms-auto w-fit-content fs-5 px-4 py-1"
                        onClick={connectAccount}
                    />
                :
                <Button
                    text={
                        <div className="d-flex flex-row align-items-center">
                            Connect Account
                            {!isSubmitted ? "" : <span className="d-flex ms-3"><Spinner size="sm" /></span>}
                        </div>
                    }
                    classes="ms-auto w-fit-content fs-5 px-4 py-2"
                    onClick={connectAccount}
                />

            }

        </div >
    )
}