export const WEB_ROUTES = {
    ACCOUNT: 'account',
    DASHBOARD: 'dashboard',
    COMMUNITY: 'community',
    MENU: 'menu',
    // submenu
    PROGRESS: 'progress',
    ACCOLADES: 'accolades',
    FEEDBACK: 'feedback',
    CONTACTUS: 'contact-us',

    TEAM: 'messages',
    SETTING: 'settings',
    PAYMENT: 'payment',
    PRIVACY: 'privacy-policy',
    PURCHASE: 'purchase',

    // PAYMENT_METHOD: 'payment-methods',
    // CURRENCY: 'currency',
    // CUSTOMER_SUPPORT: 'customer-support',
    PROFILE: 'profile',
    OTHER_PROFILE: 'profile/',
    SUBSCRIPTION: 'subscription'
}

export const BREAKPOINTS = {
    MOBILE: 991
}


export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/png,image/jpeg,image/jpg'
export const VIDEO_TYPES = '*.mp4,video/mp4,*.mov,video/mov'


export const MESSAGE_IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/png,image/jpeg,image/jpg'
export const MESSAGE_VIDEO_TYPES = '*.mp4,video/mp4,*.mov,video/mov'
export const MESSAGE_FILE_TYPES = 'application/pdf,*.pdf'

export const MESSAGE_MEDIA_TYPES = `${MESSAGE_IMAGE_TYPES},${MESSAGE_VIDEO_TYPES},${MESSAGE_FILE_TYPES}`

export const POST_TYPE_ENUM = {
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    WORKOUT: "WORKOUT",
    TEMPLATE: "TEMPLATE",
}


export const LOG_TYPE_ENUM = {
    WEEK: "week",
    MONTH: "month",
    YEAR: "year",
}

export const MESSAGE_TYPE_ENUM = {
    TEXT: "TEXT",
    FILE: "FILE",
    BADGE: "BADGE"
}

export const MESSAGE_FILE_TYPE_ENUM = {
    IMAGE: "image",
    PDF: "pdf",
    VIDEO: "video"
}

export const CARD_ENUM = {
    VISA: "visa",
}



export const CONTAINER_KEYS = {
    SPLASH_SCREEN: 'splash',
    SPLASH_SCREEN_IMAGE: 'splash-image'
}

export const LANGUAGE_CODE_ENUM = {
    ENGLISH: "enUS",
    CHINESE: "znCH",
}

export const GET_TRAINING_LOG_PAYLOAD_FORMAT = 'YYYY-MM-DD';