import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  Button,
  EmptyContainer,
  ImagePlaceholder,
  SubTitle,
  TagPlaceholder,
} from "../../Components/CustomComponents";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import CustomTabs from "./Components/CustomTabs";
import MyPost from "./Components/MyPost";
import MyWorkout from "./Components/MyWorkout";

import { useDispatch, useSelector } from "react-redux";
import OtherFollowerList from "../../Components/Modals/OtherFollowerList";
import OtherFollowingList from "../../Components/Modals/OtherFollowingList";
import { resetFollowing as resetFollowingSlice } from "../../Data/Features/communitySlice";
import {
  followUser as followUserSlice,
  resetData as resetDataSlice,
  unfollowUser as unfollowUserSlice
} from "../../Data/Features/otherUserSlice";
import { useFollowUserMutation, useGetOtherProfileQuery, useUnfollowUserMutation } from "../../Data/services/otherUserApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { ROUTES } from "./Route";

const OtherProfile = ({ prev_route = null, setPage }) => {
  const STRING = useLocalization()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: user_slug } = useParams()
  const state = useLocation().state || {};
  const [isFollowSubmitted, setIsFollowSubmitted] = useState(false);
  const [tableView, setTableView] = useState(prev_route || STRING.Post);
  const [isShowFollower, setIsShowFollower] = useState(false)
  const [isShowFollowing, setIsShowFollowing] = useState(false)

  const [followUser] = useFollowUserMutation();
  const [unfollowUser] = useUnfollowUserMutation();

  const { data: queryData, isFetching } = useGetOtherProfileQuery({ slug: user_slug }, {
    refetchOnMountOrArgChange: true,
  });

  const details = useSelector(state => state.otherUser.data);

  const data = useMemo(() => {
    if (_.isEmpty(details) && _.isEmpty(state)) return {};
    return (
      {
        ...(state ? state : {}),
        ...(queryData?.data ? queryData?.data : {}),
        ...(details ? details : {})
      }
    )

  }, [details])



  useEffect(() => {
    return () => dispatch(resetDataSlice())
  }, [])


  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleUnfollow = async () => {
    if (isFollowSubmitted) return;
    try {
      setIsFollowSubmitted(true);
      dispatch(unfollowUserSlice(user_slug))
      await unfollowUser(user_slug).unwrap();
      setIsFollowSubmitted(false)
      dispatch(resetFollowingSlice())
    } catch (e) {
      setIsFollowSubmitted(false)
      dispatch(followUserSlice(user_slug))
    }
  }

  const handleFollow = async () => {
    if (isFollowSubmitted) return;
    try {
      setIsFollowSubmitted(true);
      dispatch(followUserSlice(user_slug))
      await followUser(user_slug).unwrap();
      setIsFollowSubmitted(false)
      dispatch(resetFollowingSlice())
    } catch (e) {
      setIsFollowSubmitted(false)
      dispatch(unfollowUserSlice(user_slug))
    }
  }

  const tabOptions = [
    STRING.Posts,
    STRING.Workout
  ];

  const ComponentView = useMemo(() => {
    return {
      [STRING.Posts]: <MyPost user_slug={user_slug} setPage={setPage} />,
      [STRING.Workout]: <MyWorkout user_slug={user_slug} setPage={setPage} />,
    }
  }, []);


  const handleMessageClick = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.MESSAGE,
      data: {
        slug: data?.slug,
        name: data?.name,
        image_url: data?.image_url
      }
    }))
  }


  return (
    <div className="OtherProfile">
      {!isShowFollower ? "" : <OtherFollowerList user_slug={user_slug} handleModalClose={() => setIsShowFollower(false)} />}
      {!isShowFollowing ? "" : <OtherFollowingList user_slug={user_slug} handleModalClose={() => setIsShowFollowing(false)} />}

      <div className="d-flex flex-row align-items-center mb-3">
        <BackButton
          classes="me-2"
          isRelative={true}
          handleBackButton={handleBackButtonClick}
        />
        <SubTitle text={STRING.UsersProfile} />
      </div>

      <Row className="profile_header">
        <Col sm={12} md={12} lg={4} className="text-white">
          <div className="w-100 d-flex flex-column">
            <div className="d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-center justify-content-lg-start h-100 gap-3 scale-up-center ">
              <div>
                {(_.isEmpty(data) && isFetching) ?
                  <ImagePlaceholder classes="profile_image" />
                  :
                  <img
                    src={data.image_url}
                    alt="User Profile"
                    className="profile_image"
                  />
                }

              </div>
              <div>
                {(_.isEmpty(data) && isFetching) ? "" :
                  <div className="profile_name text-left lc-1">{data.name}</div>
                }
                {(_.isUndefined(data?.email) && isFetching) ? <Spinner size="lg" className="ms-2 mt-2" /> :
                  <>
                    <div className="profile_description lc-1">
                      {data.email}
                    </div>


                  </>
                }
              </div>
            </div>
            {(_.isEmpty(data?.sports_type) && isFetching) ? <TagPlaceholder length={7} classes="mt-2 py-3" tagClasses="tag-animate" /> :
              <div className="mt-1 py-3 userTagsContainer ">
                {(data.sports_type ?? []).map((item, index) => {
                  return <span key={index} className={'tag'}>{item}</span>
                })}
              </div>
            }
          </div>

        </Col>
        <Col
          sm={12}
          md={12}
          lg={4}
          className={`text-white my-3 count_follow_style`}
        >
          <div className={`h-100 gap_row scale-up-center`}>
            <div className="d-flex flex-column justify-items-center align-items-center">
              {_.isUndefined(data?.followers) && isFetching ? <Spinner size="lg" className="my-3" /> :
                <div className="following_followers_count" onClick={() => setIsShowFollower(true)}>{data.followers}</div>
              }
              <div className="following_followers_text">{STRING.Follower}</div>
            </div>
            <div className="d-flex flex-column justify-items-center align-items-center">
              {_.isUndefined(data?.followings) && isFetching ? <Spinner size="lg" className="my-3" /> :
                <div className="following_followers_count" onClick={() => setIsShowFollowing(true)}>{data.followings}</div>
              }
              <div className="following_followers_text">{STRING.Following}</div>
            </div>
          </div>
        </Col>
        <Col
          sm={12}
          md={12}
          lg={4}
          className={`text-white my-3 count_follow_style`}
        >
          {_.isUndefined(data?.followed_slug) && isFetching ?
            <div className="w-100 h-100 d-flex align-items-center justify-content-center"> <Spinner size="lg" className="my-3" /></div> :
            <div className="d-flex flex-column align-items-center align-items-lg-end gap-2">
              {data.followed_slug ?
                <Button
                  classes={`px-4 fs-5 fw-bold scale-in-center-slow w-75 rounded-4 following-btn`}
                  text={STRING.Following}
                  type={INPUT_TYPES.SUBMIT}
                  onClick={handleUnfollow}
                /> :
                <Button
                  classes={`px-4 fs-5 fw-bold scale-in-center-slow w-75 rounded-4`}
                  text={STRING.Follow}
                  type={INPUT_TYPES.SUBMIT}
                  onClick={handleFollow}
                />}

              <Button
                classes="px-4 fs-5 fw-bold scale-in-center-slow w-75 purchase-btn"
                text={STRING.Message}
                type={INPUT_TYPES.SUBMIT}
                onClick={handleMessageClick}
              />
            </div>
          }
        </Col>
      </Row>

      {_.isUndefined(data?.achievements) && isFetching ?
        <EmptyContainer
          classes="py-5 m-0 d-flex align-items-center justify-content-center"
          text={
            <Spinner size="lg" />
          } /> :
        <div className="d-flex flex-row flex-wrap justify-content-between gap-5">
          {(data.achievements ?? []).map((badge) => (
            <div className="badge" key={badge.slug}>
              <img
                src={badge.badgeImage}
                alt=""
                width="90"
                className="rounded-4 mt-4 mb-2"
              />
              <p className="m-0">{badge.title} </p>
            </div>
          ))}
        </div>
      }

      <Row>
        <Col sm={12} md={12}>
          <div className={`my-4 tabs_style`}>
            <CustomTabs optionList={tabOptions} tableView={tableView} setTableView={setTableView} />
          </div>
        </Col>

        <Col sm={12} md={4} />

      </Row>

      <Row className="text-white">
        <Col >{ComponentView[tableView]}</Col>
      </Row>
    </div>
  );
};

export default OtherProfile;
