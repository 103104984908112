// const BASE_URL = "http://localhost:5000/";
const BASE_URL = "http://api.betterwork.trangotechdevs.com:3000/";
// const BASE_URL = "https://api.betterwork.trangotechdevs.com/";

const BASE_URL_STAGING =
  "http://staging.api.betterwork.trangotechdevs.com:3025/";

const API_URL = BASE_URL + "api/user/";
const UPLOAD_URL = BASE_URL + "api/upload";

const INITIAL_TOKEN = "68bdbd1c-a197-4d78-9483-3f8f292a42be";

export { BASE_URL, BASE_URL_STAGING, API_URL, UPLOAD_URL, INITIAL_TOKEN };
