import { createSlice } from "@reduxjs/toolkit";
import { userAuthApi } from "../services/userAuthApi";

const initialState = {
  user: null,
  isLoggedIn: undefined,
  isSocketConnected: false,
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState,
  reducers: {
    setUser: (state, actions) => {
      state.isLoggedIn = true
      state.user = actions.payload
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isSocketConnected = false
      state.user = {};
    },
    socketConntected: (state, actions) => {
      return {
        ...state,
        isSocketConnected: true
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.isLoggedIn = true
          state.user = payload.data;
        }
      )
      .addMatcher(userAuthApi.endpoints.loginUser.matchRejected, (state) => {
        state.user = null;
      });
    builder
      .addMatcher(
        userAuthApi.endpoints.toggleNotification.matchPending,
        (state, { payload }) => {
          state.user = {
            ...state.user,
            is_pushNotification: !state.user.is_pushNotification
          };
        }
      )
      .addMatcher(userAuthApi.endpoints.toggleNotification.matchRejected, (state) => {
        state.user = {
          ...state.user,
          is_pushNotification: !state.user.is_pushNotification
        };
      });
  },
});

export const { setUser, logout, socketConntected } = authSlice.actions;

export default authSlice.reducer;
