import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button, Input, SubTitle } from "../../../Components/CustomComponents";
import { INPUT_TYPES } from "../../../Constant/InputTypesAndPatternsConstant";
import { useAddCardMutation } from "../../../Data/services/cardApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import { ErrorToast, SuccessToast } from "../../../Hooks/useToast";

const PaymentFormModal = () => {
  const STRING = useLocalization()
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState('')
  const [loadButton, setLoadButton] = useState(false);

  const [createCard] = useAddCardMutation();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });


  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (!name) return ErrorToast("Card name is required");
    try {
      setLoadButton(true);
      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );


      const payload = {}
      payload.card_token = token?.id
      payload.card_name = name
      payload.card_number = "test"
      payload.card_cvc = "test"
      payload.card_expire = "test"

      const response = await createCard(payload).unwrap();
      setLoadButton(false)
      setName('')
      elements.getElement(CardElement).clear()
      SuccessToast("Card added successfully")

    } catch (error) {
      ErrorToast(error?.message || error?.data?.message);
      setLoadButton(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-payment">
      <div className="addNewCard mb-3">
        <SubTitle text={STRING.AddNewCard} />

        <div className="buttonDiv">
          <Button
            classes="px-4 fs-5 fw-bold rounded-4 scale-in-center-slow"
            text={<div className='d-flex align-items-center justify-content-center'>
              {STRING.Add}{loadButton ? <span className='ms-2 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
            </div>}
            type={INPUT_TYPES.SUBMIT}
            disabled={!stripe || loadButton || !isValid}
          />
        </div>
      </div>
      <Row>
        <Col xs={12}>
          <InputGroup>
            <Input
              classes="mt-2 mb-3"
              placeholder={STRING.NameOnCard}
              type={INPUT_TYPES.TEXT}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <div className="PaymentCard mt-2">
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    iconColor: "#c4f0ff",
                    color: "#fff",
                    padding: "20px",
                    borderRadius: "20px",
                    fontWeight: "500",
                    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                    fontSize: "18px",
                    fontSmoothing: "antialiased",
                    ":-webkit-autofill": {
                      color: "white",
                    },
                    "::placeholder": {
                      color: "white",
                    },
                  },
                  invalid: {
                    iconColor: "#FFC7EE",
                    color: "#FFC7EE",
                  },
                },
              }}
            />
          </div>

        </Col>
      </Row>
    </Form>
  );
};

export default PaymentFormModal;
