import React, { useState } from "react";
import { InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import { useSelector } from "react-redux";
import { useSharePostMutation } from "../../Data/services/CommunityApi";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import PostImageContainer from "../CustomComponents/PostImageContainer";
import UserAvatarWithNameAndTag from "../CustomComponents/UserAvatarWithNameAndTag";


const ShareCommunityPostModal = ({ data = {}, handleModalClose }) => {
  const STRING = useLocalization()
  const [modalClass, setModalClass] = useState(
    "shareCommunityPostModalContainer scale-in-hor-center"
  );
  const [sharePost] = useSharePostMutation();

  const user = useSelector(state => state.authInfo.user)

  const [caption, setCaption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [mediaImage, setMediaImage] = useState('');

  const handleModalCloseWithAnimation = () => {
    setModalClass("shareCommunityPostModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  const handleSubmit = async () => {
    if (isSubmitted) return;

    try {
      setIsSubmitted(true)
      const payload = {};
      payload.caption = caption || '';
      const result = await sharePost({ slug: data.slug, data: payload }).unwrap();
      handleModalCloseWithAnimation();
      SuccessToast("Post shared successfully.")
    }
    catch (e) {
      if (e?.data?.code === 400) {
        ErrorToast(e.data?.message)
      }
      setIsSubmitted(false)
    }
  }

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <p className="fs-4 fw-medium m-0">{STRING.SharePost}</p>

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>

          <br></br>

          <div className="d-flex flex-row justify-content-between w-100">
            <UserAvatarWithNameAndTag
              userName={user.name}
              userTag={''}
              avatarImage={user.image_url}
            />
          </div>

          <hr style={{ width: "100%" }}></hr>

          <div className="d-flex flex-row justify-content-between w-100">
            <UserAvatarWithNameAndTag
              userName={data.user_name}
              userTag={''}
              avatarImage={data.user_image}
            />
          </div>

          <br></br>

          <div className="post-image">
            <PostImageContainer
              image={data.image_url}
              thumbnail={data.thumbnail_url}
              is_paid={data.is_paid}
            />
          </div>

          <br></br>

          <InputGroup>
            <Input
              placeholder={STRING.WriteCaption2}
              type={INPUT_TYPES.TEXT}
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </InputGroup>

          <Button
            classes="scale-in-center-slow fs-5 mt-1"
            text={<div className='d-flex align-items-center justify-content-center'>
              {STRING.Share}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
            </div>}
            type={INPUT_TYPES.SUBMIT}
            onClick={handleSubmit}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default ShareCommunityPostModal;
