import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { BackButton, Button, EmptyContainer, Loader } from "../../Components/CustomComponents";
import { useGetWorkoutDetailsQuery } from "../../Data/services/dashboardApi";
import ExerciseCard from "./Components/ExerciseCard";
import HorizontalImageWithGradient from "./Components/HorizontalImageWithGradient";
import EditWorkoutModal from "../Modals/EditWorkoutModal";
import { useLocalization } from "../../Hooks/useLocalization";

const WorkoutView = ({ data = {}, handleBackButtonClick }) => {
  const STRING = useLocalization()
  const { data: details, isFetching } = useGetWorkoutDetailsQuery(data.slug);

  const [isEditModal, setIsEditModal] = useState(false)
  const workOutData = useMemo(() => {
    if (_.isEmpty(details) && _.isEmpty(data)) return {};
    return (
      {
        ...(data ? data : {}),
        ...(details?.data ? details.data : {})
      }
    )

  }, [details])



  return (
    <div className="WorkoutView focus-in-expand-slow">
      {
        !isEditModal ? "" :
          <EditWorkoutModal data={workOutData} handleModalClose={() => setIsEditModal(false)} />
      }
      <div className="headerContainer">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <BackButton
            classes="me-3"
            isRelative={true}
            handleBackButton={handleBackButtonClick}
          />
        </div>
        {!data?.is_mine ? "" :
          <Button
            onClick={() => setIsEditModal(true)}
            classes='ms-auto w-fit-content px-4 py-2'
            text={isFetching ? <Spinner size="sm" /> : "Edit"}
          />
        }
      </div>
      {_.isEmpty(workOutData) ? <Loader /> :
        <>
          <HorizontalImageWithGradient image={workOutData.image_url} />
          <div className="mt-2 mb-4">
            <div className="w-100 d-flex flex-row flex-wrap justify-content-between mt-2 mb-4 tags">
              <p className="m-0 fs-3 fw-bold text-white">
                {workOutData.title}
              </p>
              <div className="w-100 d-flex flex-row flex-wrap justify-content-left">
                {(workOutData.tags ?? []).map((item, index) => {
                  return <p
                    className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3"
                    key={index}
                  >
                    {item}
                  </p>
                }
                )}
              </div>

              <p className="w-100 m-0 text-white fw-bold fs-4 mt-2 mb-1">{STRING.WorkoutDescription}</p>
              {!workOutData.description ?
                (isFetching ? <span className="text-light ps-1 "><Spinner size="sm" /></span> : "") :
                <p className="m-0 fs-6 text-granite">{workOutData.description}</p>
              }

            </div>

            <p className="m-0 mt-4 text-white fs-4">{STRING.Exercises}</p>

            {_.isEmpty(workOutData?.exercise) ?
              (isFetching ? <Loader /> :
                <EmptyContainer text={STRING.WorkoutExerciseNotFound} />
              ) :
              <Row className={` mt-2 mb-2`} gutter={[0, 24]}>
                {
                  (workOutData?.exercise ?? []).map((exercise, index) => {
                    return < Col
                      sm={12} md={6}
                      key={index}
                    >
                      <ExerciseCard
                        exerciseNumber={`${STRING.Exercise} ${index + 1}`}
                        heading={exercise.title}
                        text={exercise.details}
                      />
                    </Col>
                  }
                  )}
              </Row>
            }

          </div>
        </>}
    </div >
  );
};

export default WorkoutView;
