import React, { useState } from 'react';
import { InputGroup, Spinner } from 'react-bootstrap';
import { FaCircleXmark } from 'react-icons/fa6';
import { INPUT_TYPES } from '../../Constant/InputTypesAndPatternsConstant';
import { Button, Input } from '../CustomComponents';
import ModalContainer from '../ModalContainer';
// import cssStyles from './styles/notification.module.css';
import { message, Upload } from 'antd';
import _ from 'lodash';
import { IMAGES } from '../../Constant/ImageConstant';
import { TOAST_MESSAGE } from '../../Constant/Message';
import { useCreateForumMutation } from '../../Data/services/dashboardApi';
import { useLocalization } from '../../Hooks/useLocalization';
import Toast, { ErrorToast, SuccessToast } from '../../Hooks/useToast';
import { IMAGE_TYPES } from '../../Constant/constants';

const { Dragger } = Upload;

const AddForumModal = ({ handleModalClose }) => {
    const STRING = useLocalization()
    const [modalClass, setModalClass] = useState('addForumModalContainer scale-in-hor-center');
    const [postForum] = useCreateForumMutation();


    const [mediaImage, setMediaImage] = useState('');
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleModalCloseWithAnimation = () => {
        setModalClass('addForumModalContainer scale-out-horizontal');
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };

    // MARK: UPLOAD IMAGE 

    const beforeUpload = (file) => {
        const isJpgOrPng = IMAGE_TYPES.includes(file.type);
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast(STRING.FileFormatRequired, 'error', false);
        }
        else if (!isLt2M) {
            Toast(STRING.ImageSizeRequired, 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    // upload files
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: IMAGE_TYPES,
        value: !_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage),
        beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
        // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
        customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
        // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
                setMediaImage(info.file.originFileObj);

            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            setMediaImage('');
        },
    };


    const handleSubmit = async () => {
        if (isSubmitted) return;

        if (_.isEmpty(title)) return ErrorToast(STRING.ForumTopicRequired)
        if (_.isEmpty(description)) return ErrorToast(STRING.ForumDescriptionRequired)

        try {
            setIsSubmitted(true);
            const formData = new FormData();
            formData.append('image_url', mediaImage)
            formData.append('title', title)
            formData.append('description', description)

            const response = await postForum(formData);

            if (response.error)
                if (response.error.status === 400) {
                    const message = await response.error.data.message;
                    Toast(`${message}`, "error");
                }
                else {
                    throw new Error('SERVER_ERROR');
                }

            if (!response.error && response.data.code === 200) {
                SuccessToast(TOAST_MESSAGE.SUCCESS.FORUM_CREATED);
                handleModalCloseWithAnimation()
            }
        } catch (error) {
            if (error?.data && error?.data?.message) {
                Toast(error?.data?.message, "error");
            } else {
                Toast(TOAST_MESSAGE.ERROR.FORUM_CREATION, "error");
            }
        }
        finally {
            setIsSubmitted(false)
        }
    }

    return (
        <div>
            <ModalContainer
                handleClose={handleModalCloseWithAnimation}
            >
                <div className={modalClass}>

                    <div className='d-flex align-items-center w-100 justify-content-between'>
                        <p className='fs-4 fw-medium m-0'>
                            {STRING.CreateCommunityForm}
                        </p>

                        <div className={'closeBtn'}>
                            <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalCloseWithAnimation} />
                        </div>
                    </div>

                    <br></br>

                    {
                        // MARK: DRAGGER 
                    }
                    <Dragger Dragger  {...props} className='w-100' >

                        {!_.isEmpty(mediaImage) ?
                            <img
                                src={!_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage)}
                                alt="avatar"
                                style={{ width: '100%', height: '100px' }}
                                className="img-fluid"
                            /> :
                            <div>
                                <p className="ant-upload-drag-icon">
                                    <img src={IMAGES.UPLOAD_ICON} alt="" />
                                </p>
                                <p className="ant-upload-text">{STRING.DropImage}</p>
                                <p className="ant-upload-hint"> {STRING.ImageSupport} </p>
                            </div>
                        }

                    </Dragger>

                    <br></br>

                    <InputGroup>
                        <Input
                            placeholder={STRING.EnterTopicHere}
                            type={INPUT_TYPES.TEXT}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </InputGroup>

                    <InputGroup>
                        <Input
                            placeholder={STRING.AddNewThread}
                            type={INPUT_TYPES.TEXTAREA}
                            rows={3}
                            as={INPUT_TYPES.TEXTAREA}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </InputGroup>

                    <Button
                        classes="scale-in-center-slow fs-5 mt-1"
                        text={
                            <div className='d-flex align-items-center justify-content-center'>
                                {STRING.PostCommunityButtonText}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                            </div>
                        }
                        type={INPUT_TYPES.SUBMIT}
                        onClick={handleSubmit}
                    />

                </div>
            </ModalContainer >
        </div >
    )

}

export default AddForumModal