import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button, SubTitle } from "../../Components/CustomComponents";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useGetWorkoutDaysQuery } from "../../Data/services/userApi";
import { formatDate } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";
import MonthlyCalendar from "./Components/MonthlyCalendar";
import WeeklyCalendar from "./Components/WeeklyCalendar";
import YearlyCalendar from "./Components/YearlyCalendar";

const Progress = ({ setPage }) => {
  const STRING = useLocalization()
  const { image_url, name, createdAt } = useSelector(state => state.authInfo.user);

  const { data: workoutData, isFetching: isWorkoutFetching } = useGetWorkoutDaysQuery();

  const optionList = [STRING.Week, STRING.Month, STRING.Year];

  const [currentView, setCurrentView] = useState(STRING.Week);

  const handleOptionChange = (e) => {
    setCurrentView(e);
  };

  return (
    <div className="Progress">
      <SubTitle text={STRING.Progress} />
      <div className="first-row slide-in-left-slow">
        <div className="left-side">
          <img src={image_url} alt="" />
          <div className="user-details">
            <SubTitle text={name} classes="fs-5" />
            <p>{`${STRING.MemberSince} ${formatDate(createdAt)}`}</p>
          </div>
        </div>
        <div className="right-side scale-in-center-slow">
          <Button
            text={isWorkoutFetching ? <Spinner size="sm" /> : `${(workoutData?.data?.count ?? 0)} ${STRING.WorkoutDays}`}
            type={INPUT_TYPES.SUBMIT}
            classes="fs-6 fw-light px-4 py-1"
          />
        </div>
      </div>
      <div className="second-row focus-in-expand">
        {currentView === STRING.Week ? <WeeklyCalendar>
          <SelectFieldComponent
            key={currentView}
            optionsList={optionList}
            firstOption={optionList[0]}
            onSelectChange={handleOptionChange}
            className={"select bg-darkJungleGreen"}
          />
        </WeeklyCalendar> : ""}

        {currentView === STRING.Month ? (
          <MonthlyCalendar year={2024} month={7} >
            <SelectFieldComponent
              key={currentView}
              optionsList={optionList}
              firstOption={optionList[1]}
              onSelectChange={handleOptionChange}
              className={"select bg-darkJungleGreen"}
            />
          </MonthlyCalendar>
        ) : (
          ""
        )}

        {currentView === STRING.Year ? <YearlyCalendar >
          <SelectFieldComponent
            key={currentView}
            optionsList={optionList}
            firstOption={optionList[2]}
            onSelectChange={handleOptionChange}
            className={"select bg-darkJungleGreen"}
          />
        </YearlyCalendar> : ""}
      </div>
    </div>
  );
};

export default Progress;