// Need to use the React-specific entry point to import createApi
import { END_POINTS } from "../../Constant/EndPointsContants";
import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";


export const cardApi = baseApi.injectEndpoints({
    mode: "no-cors",
    endpoints: (builder) => ({
        addCard: builder.mutation({
            query: (data) => {
                return {
                    url: END_POINTS.CARD.ADD,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        getCard: builder.query({
            query: () => {
                return {
                    url: END_POINTS.CARD.GET,
                    method: "GET",
                };
            },
            providesTags: ['User/CARD'],
        }),
        removeCard: builder.mutation({
            query: (slug) => {
                return {
                    url: END_POINTS.CARD.REMOVE(slug),
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
    }),
});


export const {
    useAddCardMutation,
    useGetCardQuery,
    useRemoveCardMutation
} = cardApi