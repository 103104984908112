import React from "react";
import { useSelector } from "react-redux";
import { Button, SubTitle } from "../../Components/CustomComponents";
import { IMAGES } from "../../Constant/ImageConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useGetContactUsQuery } from "../../Data/services/staticContentApi";
import { useLocalization } from "../../Hooks/useLocalization";

const ContactUs = ({ setPage }) => {
  const STRING = useLocalization()
  const { data, isFetching, refetch } = useGetContactUsQuery();
  const contactData = useSelector((state) => state.staticContent.contact_us);

  const sendEmail = ({ contactData }) => {
    const email = contactData?.text;

    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="ContactUs focus-in-expand">
      <SubTitle text={STRING.ContactUs} />
      <div className="contact-row">
        <div className="left-side">
          <img src={IMAGES.CONTACT_IMAGE} alt="" />
          <div className="contact-details">
            <SubTitle text={STRING.HavingIssue} classes="fs-4" />
            <p>
              <b> {STRING.ContactInfo} </b>
            </p>
            <p>{contactData?.text}</p>
          </div>
        </div>
        <div className="right-side scale-in-center-slow">
          <Button
            classes="px-4"
            text={STRING.CopyEmail}
            type={INPUT_TYPES.SUBMIT}
            onClick={sendEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
