import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Input
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";

import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import Logo from "../../Components/CommonComponents/Logo";
import { IMAGES } from "../../Constant/ImageConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import { RegisterPayload } from "../../Constant/PayloadConstant/RegisterPayloadConstant";
import { requestSportsTypeDTOs } from "../../Data/DTO/registerDTO";
import { useGetSportsLookupQuery } from "../../Data/services/lookupApi";
import { useRegisterUserMutation } from "../../Data/services/userAuthApi";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import LoginLink from "./Components/LoginLink";
import RegisterYourself from "./Components/RegisterYourself";

import { RxCross1 } from "react-icons/rx";
import { LiaCheckSolid } from "react-icons/lia";

export default function Register({ setPage, setEmail, setAfterRegister }) {
  const STRING = useLocalization()

  useGetSportsLookupQuery({
    refetchOnMountOrArgChange: true,
  });

  const look_data = useSelector((state) => state.lookups.sportsType);

  const loading = [
    { label: "Loading...", value: "Loading...", disabled: true },
  ];

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange"
  });

  // Watch the password field value to compare with confirm password
  const password = watch("password");

  const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);
  const handleSendToOTPVerification = () => setPage(ACCOUNT_ENUM.OTP);

  const [registerUser, { isError, isLoading, isSuccess }] =
    useRegisterUserMutation();

  const handleRegister = async (data) => {
    data.sports_type = requestSportsTypeDTOs(data.sports_type);

    try {
      const response = await registerUser(data);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
      }

      if (!response.error && response.data.code === 200) {
        // Toast('You are registered successfully, Now Login with your credentials', "success");
        SuccessToast(STRING.AfterRegisterSuccessMessage);

        // handleLoginClick();
        setEmail(data.email);
        setAfterRegister(true);
        handleSendToOTPVerification();
      }
    } catch (error) {
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during registration", "error");
      }
    }
  };

  return (
    <AccountLayout leftSideImage={IMAGES.LOGIN}>
      <Logo />
      <RegisterYourself />

      <Form
        onSubmit={handleSubmit(handleRegister)}
        className="w-100 scale-in-center"
      >
        <Controller
          control={control}
          name={RegisterPayload.Name}
          rules={{
            required: STRING.FullNameRequired,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup className="my-1">
              <Input
                placeholder={STRING.FullName}
                type={INPUT_TYPES.TEXT}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                classes="m-0"
              />
              {!errors?.name?.message ? "" : <p className="w-100 error_message m-0 ms-2">{errors?.name?.message}</p>}
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Email}
          rules={{
            required: STRING.EmailRequired,
            pattern: {
              value: INPUT_PATTERNS.EMAIL_PATTERNS,
              message: STRING.ValidEmailRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup className="my-2">
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                classes="m-0"
              />
              {!errors?.email?.message ? "" : <p className="w-100 error_message m-0 ms-2">{errors?.email?.message}</p>}
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.SportsType}
          rules={{
            required: "Must be select a sports type.",
          }}
          defaultValue={[]}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="my-2">
              <MultiSelect
                options={look_data ?? loading}
                value={value}
                onChange={onChange}
                labelledBy="Select Sports Type"
                hasSelectAll={false}
                disableSearch={true}
              />
              <ul>
                <li className="note label-placeholder fs-8 m-0">You can change their selection from the profile section</li>
              </ul>
              {!errors?.sports_type?.message ? "" : <p className="w-100 error_message m-0 ms-2">{errors?.sports_type?.message}</p>}
            </div>
          )}
        />


        <Controller
          control={control}
          name={RegisterPayload.Password}
          rules={{
            required: STRING.PasswordRequired,
            minLength: {
              value: 6,
              message: STRING.PasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.PasswordPatternRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => {
            return <div className="mb-2">
              <InputGroup>
                <Input
                  classes="mt-2 mb-2"
                  placeholder={STRING.CreatePassword}
                  type={INPUT_TYPES.PASSWORD}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </InputGroup>
              <div className="px-3">
                <div className="d-flex flex-row gap-3 align-items-center">
                  {(INPUT_PATTERNS.ATLEAST_1_NUMBER_PATTERNS).test(value) ?
                    <span className="color-success"><LiaCheckSolid /></span> :
                    <span className="color-error"><RxCross1 /></span>
                  }
                  <p className="note label-placeholder fs-8 m-0">
                    Atleast 1 number
                  </p>
                </div>
                <div className="d-flex flex-row gap-3 align-items-center">
                  {value && (INPUT_PATTERNS.ATLEAST_8_CHARACTER_INCLUDING_ALPHABETS_PATTERNS).test(value) ?
                    <span className="color-success"><LiaCheckSolid /></span> :
                    <span className="color-error"><RxCross1 /></span>
                  }
                  <p className="note label-placeholder fs-8 m-0">
                    Atleast 8 characters including alphabets
                  </p>
                </div>
                <div className="d-flex flex-row gap-3 align-items-center">
                  {value && (INPUT_PATTERNS.ATLEAST_1_SPECIAL_CHARACTER_PATTERNS).test(value) ?
                    <span className="color-success"><LiaCheckSolid /></span> :
                    <span className="color-error"><RxCross1 /></span>
                  }
                  <p className="note label-placeholder fs-8 m-0">
                    Atleast 1 special character
                  </p>
                </div>
              </div>
            </div>

          }
          }
        />

        <Controller
          control={control}
          name={RegisterPayload.ConfirmPassword}
          rules={{
            required: STRING.ConfirmPasswordRequired,
            validate: (value) =>
              (value !== password) ? STRING.ConfirmPasswordMustMatch : null,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup className="my-2">
              <Input
                classes="m-0"
                placeholder={STRING.ConfirmPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              {!errors?.confirm_password?.message ? "" : <p className="w-100 error_message m-0 ms-2">{errors?.confirm_password?.message}</p>}
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="agree"
          rules={{
            required: "Must be agree with Condition and Privacy Policy.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Checkbox
              label={STRING.ContinueWithSocialLogin}
              secondLabel={STRING.TermsAndCondition}
              classes="mt-2 mb-2"
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />

        <Button
          classes="mt-4 mb-2 puff-in-hor"
          text={isLoading ? STRING.Sending : STRING.Register}
          type={INPUT_TYPES.SUBMIT}
          disabled={isLoading}
        />
      </Form>

      <LoginLink handleLoginClick={handleLoginClick} />
    </AccountLayout>
  );
}
