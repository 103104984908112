import axios from 'axios'
import { saveAs } from 'file-saver'
import React, { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { API_URL } from '../../../config/config'
import { END_POINTS } from '../../../Constant/EndPointsContants'
import { ErrorToast } from '../../../Hooks/useToast'
import { ROUTES } from '../Route'
import DownloadTag from './DownloadTag'
import PremiumSign from './PremiumSign'

const HorizontalCard = ({ data, setPage }) => {
    const user = useSelector(state => state.authInfo.user)
    const [isDownloading, setIsDownloading] = useState(false)
    const isPremium = useMemo(() => !!data.is_paid, []);
    const isMine = useMemo(() => !!data.is_mine, []);

    const handleViewPage = () => {
        if (isPremium) {
            setPage({
                route: ROUTES.WORKOUT_VIEW_PREMIUM,
                data: data
            });

        } else {

            setPage({
                route: ROUTES.WORKOUT_VIEW,
                data: data
            });
        }
    }

    const handleDownload = async (e) => {
        e.stopPropagation()
        if (isDownloading) return;
        try {
            setIsDownloading(true)
            const res = await axios.get(API_URL + END_POINTS.Dashboard.DOWNLOAD_WORKOUT(data.slug), {
                headers: {
                    "Authorization": "Bearer " + user.api_token,
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'arraybuffer',

            })
            const blob = new Blob([res.data], { type: 'application/pdf' })
            console.log(blob);
            
            saveAs(blob, "workout.pdf")
            setIsDownloading(false)
        } catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            setIsDownloading(false)
        }
    }

    return (
        <>
            <div className='workoutContainer' onClick={handleViewPage} role="button">

                <div className='imageWrapper'>
                    <img src={data.image_url} alt="" className={`image`} />
                    <div className='gradientOverlay'></div>
                </div>

                {/* {
                    isPremium ?
                        <PremiumSign />
                        : <FreeTag text={'Free'} />
                } */}
                {
                    isPremium ?
                        <PremiumSign />
                        :
                        isDownloading ?
                            <DownloadTag text={<Spinner size="sm" />} isEdit={false} />
                            : <>
                                <DownloadTag text={'Download Workout'} onClick={handleDownload} />
                                {/* <EditSign /> */}
                            </>
                }
                {
                    // isTemplate ? <TemplateSign text={'Template 1'} /> : ''
                }

                <p className='title lc-1 m-0 fs-5'> {data.title} </p>
            </div>

        </>
    )
}

export default HorizontalCard
