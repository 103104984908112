import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Logo from "../../Components/CommonComponents/Logo";
import { BackButton, Button, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { IMAGES } from "../../Constant/ImageConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import { LoginPayload } from "../../Constant/PayloadConstant/LoginPayloadConstant";
import { useSendOtpMutation } from "../../Data/services/userAuthApi";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import ForgotPasswordInstructions from "./Components/ForgotPasswordInstructions";
import { ACCOUNT_ENUM } from "./Route";

export default function ForgotPassword({ setPage, setEmail }) {
  const STRING = useLocalization()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleOtpPage = () => {
    setPage(ACCOUNT_ENUM.OTP);
  };

  const [sendOtp, { isError, isLoading, isSuccess, error, data }] =
    useSendOtpMutation();

  // useEffect(() => {
  //   if (isError) {
  //     console.log(error);
  //     const message = error.data.message;
  //     Toast(`${message}`, "error");
  //   }

  //   if (isSuccess) {
  //     console.log(data);
  //     SuccessToast(data?.message);
  //     setEmail(data.email);
  //     handleOtpPage();
  //   }
  // }, [isError, isSuccess, data, error, setEmail, handleOtpPage]);

  const handleForgotEmail = async (data) => {
    console.log(data);
    // handleGotoSetPassword();
    try {
      const response = await sendOtp(data);
      console.log(response);

      if (
        response.error &&
        response.error.status >= 400 &&
        response.error.status <= 499
      ) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        console.log(message);
      }

      if (!response.error && response.data.code === 200) {
        console.log("success");
        SuccessToast(response?.data?.message);
        setEmail(data.email);
        handleOtpPage();
      }
    } catch (error) {
      console.log(error);
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("Something went wrong.", "error");
      }
    }
  };

  return (
    <AccountLayout
      leftSideImage={IMAGES.PASSWORD}
      // hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : null
        : ""}

      <Logo />

      <br></br>
      <br></br>

      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />

      <ForgotPasswordInstructions />

      <Form onSubmit={handleSubmit(handleForgotEmail)} className="w-100">
        <Controller
          control={control}
          name={LoginPayload.Email}
          rules={{
            required: STRING.EmailRequired,
            pattern: {
              value: INPUT_PATTERNS.EMAIL_PATTERNS,
              message: STRING.ValidEmailRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Button
          classes="mt-3 mb-2 scale-in-center-slow"
          text={isLoading ? STRING.Sending : STRING.Continue}
          type={INPUT_TYPES.SUBMIT}
          disabled={isLoading}
          // onClick={handleSubmit}
        />
      </Form>
    </AccountLayout>
  );
}
