import React from 'react'
import { SubTitle } from '../../../Components/CustomComponents'
import { useLocalization } from '../../../Hooks/useLocalization'

function RegisterYourself() {
    const STRING = useLocalization()
    return (
        <div className='w-100'>
            <p
                className="w-100 m-0"
                style={{}}
            >
                {STRING.HelloThere}
            </p>

            <SubTitle
                classes="mb-1 w-100 fw-light focus-in-expand"
                text={STRING.RegisterYourself}
            />
        </div>
    )
}

export default RegisterYourself
