import React from 'react'
import { FaCircleCheck } from 'react-icons/fa6'
import { useLocalization } from '../../Hooks/useLocalization'
import UserAvatarWithNameAndTag from '../CustomComponents/UserAvatarWithNameAndTag'

const MemberSelectionCard = ({ isSelected, onSelect, data = {} }) => {
  const STRING = useLocalization()
  return (
    <div onClick={onSelect} className="d-flex flex-row justify-content-between align-items-center w-100 my-1 cursor-pointer">

      <UserAvatarWithNameAndTag
        userName={data.username}
        userTag={data.name}
        avatarImage={data.image_url}
      />

      <FaCircleCheck
        style={{
          fontSize: "15px",
          color: isSelected ? "#FBBE21" : "#26262E",
          // backgroundColor: "white",
          borderRadius: "100px",
        }}
      />

    </div>
  )
}

export default MemberSelectionCard