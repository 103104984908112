import React from 'react'
import UserAvatarWithNameAndTag from '../CustomComponents/UserAvatarWithNameAndTag'

const MemberCard = ({ data = {} }) => {
    return (
        <div className="d-flex flex-row justify-content-between align-items-center w-100 my-1 cursor-pointer">
            <UserAvatarWithNameAndTag
                userName={data.username}
                userTag={data.name}
                avatarImage={data.image_url}
            />
        </div>
    )
}

export default MemberCard