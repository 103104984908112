import React, { useEffect, useState } from "react";
import { Button, EmptyContainer, SubTitle } from "../../Components/CustomComponents";

import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GridCard from "../../Components/PostDetails/Components/GridCard";
import { CARD_ENUM, WEB_ROUTES } from "../../Constant/constants";
import { IMAGES } from "../../Constant/ImageConstant";
import { baseApi } from "../../Data/services/baseApi";
import { useGetCardQuery } from "../../Data/services/cardApi";
import { useCreatePaymentMutation } from "../../Data/services/paymentApi";
import { baseRoute } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";
import { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import PaymentProcess from "./Components/PaymentPopUp";

const Purchase = () => {
    const STRING = useLocalization()
    const navigate = useNavigate()
    const { state } = useLocation()
    const itemData = state?.data || {}
    if (_.isEmpty(itemData)) {
        navigate(baseRoute(WEB_ROUTES.DASHBOARD))
    }

    const dispatch = useDispatch()
    const { data, isLoading } = useSelector(state => state.card)


    const [isAddCard, setIsAddCard] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [selectedCard, setSelectedCard] = useState(_.isEmpty(data) ? undefined : data[0].slug)

    const [createPayment] = useCreatePaymentMutation();
    const fetchCard = useGetCardQuery({
        refetchOnMountOrArgChange: false,
    });


    useEffect(() => {
        if (!_.isUndefined(selectedCard) || _.isEmpty(data)) return
        setSelectedCard(data[0].slug);

    }, [data])



    const handleCardSelect = (slug) => {
        setSelectedCard(slug)
    }

    const handlePurchaseItem = async () => {
        if (isSubmitted || _.isUndefined(selectedCard)) return;

        try {

            setIsSubmitted(true);
            const payload = {}
            payload.card_slug = selectedCard;
            payload.amount = itemData?.price;
            payload.product_slug = itemData?.slug;

            const response = await createPayment(payload).unwrap();
            dispatch(baseApi.util.resetApiState());
            navigate(baseRoute(WEB_ROUTES.PROFILE), { state: { tab: STRING['MyPurchase'] } })
            SuccessToast("Item purchased successfully")

        } catch (error) {
            ErrorToast(error?.message || error?.data?.message);
        }
        finally {
            setIsSubmitted(undefined);
        }

    }

    return (
        <div className="Purchase focus-in-expand">
            <GridCard data={itemData} />
            <SubTitle text={STRING.CurrentCard} classes="mt-5" />
            {isLoading ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> :
                _.isEmpty(data) ? <EmptyContainer text={STRING.CardNotFound} /> :
                    data.map((item) => {
                        return <div
                            key={item.slug}
                            className={`selectedCard slide-in-left-slow my-3 ${(selectedCard === item.slug ? "selected" : "")}`}
                            onClick={() => handleCardSelect(item.slug)}
                        >
                            <div className="cardDetails">
                                <div>
                                    <p> {item.card_name} </p>
                                    <p> **** **** **** {item.card_number} </p>
                                </div>
                                {(item.card_brand === CARD_ENUM.VISA) ?
                                    <img src={IMAGES.CARD_ICON} alt="" />
                                    : ""}
                            </div>
                        </div>
                    })
            }
            <br></br>
            <Button
                classes="mt-2 mb-5 px-4 fs-5 fw-bold rounded-4 scale-in-center-slow"
                text={
                    <div className='d-flex align-items-center justify-content-center'>
                        {!isSubmitted ? `${STRING.Purchase} $${itemData.price} ` :
                            <span className="d-flex flex-row align-items-center">Purchasing<span className='ms-2 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span></span>}
                    </div>
                }
                onClick={handlePurchaseItem}
                disabled={_.isUndefined(selectedCard) || isSubmitted}
            />
            {!isAddCard ?
                <Button
                    classes="px-4 fs-5 fw-bold rounded-4 scale-in-center-slow"
                    text={STRING.Add}
                    onClick={() => setIsAddCard(true)}
                /> :
                <PaymentProcess />
            }
        </div >
    );
};

export default Purchase;
