import React from "react";
import styles from "./index.module.scss";

import { SVG } from '../../Constant/SvgConstant'
import { Form, Spinner } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { useLocalization } from "../../Hooks/useLocalization";

export const ShadowContainer = () => {
  return <div className={styles.ShadowContainer}></div>;
};

export const BackButton = ({
  isRelative = false,
  classes = "",
  handleBackButton = () => { },
}) => {
  return (
    <div
      className={`${styles.BackButton
        } ${classes} box-shadow slide-in-right-slow ${isRelative ? styles.relative : ""
        }`}
      onClick={handleBackButton}
    >
      <IoIosArrowBack />
    </div>
  );
};

export const Input = ({
  type = "text",
  classes = "mt-1 mb-2",
  style = {},
  size = "lg",
  placeholder = "",
  value = "",
  disabled = false,
  as = "input",
  name = "",
  rows = 1,
  onChange = () => { },
  onKeyDown = () => { },
}) => {
  return (
    <Form.Control
      name={name}
      size={size}
      className={`${styles.Input} ${classes}`}
      placeholder={placeholder}
      type={type}
      value={value}
      as={as}
      rows={rows}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export const Description = ({
  text = "",
  secondText = "",
  classes = "",
  onClick = () => { },
}) => {
  return (
    <p className={`${styles.Description} ${classes} fs-7`} onClick={onClick}>
      {text} <b>{secondText}</b>{" "}
    </p>
  );
};

export const CardDescription = ({ text = "", classes = "" }) => {
  return <p className={`${styles.Description} ${classes} fs-10`}>{text}</p>;
};

export const Title = ({ text = "", classes = "" }) => {
  return <h1 className={`${styles.Header} ${classes}`}>{text}</h1>;
};

export const SubTitle = ({ text = "", classes = "" }) => {
  return <h2 className={`${styles.SubTitle} ${classes}`}>{text}</h2>;
};
export const CardTitle = ({ text = "", classes = "" }) => {
  return <h6 className={`${styles.CardTitle} ${classes} `}>{text}</h6>;
};
export const CardMatchTitle = ({ text = "", classes = "" }) => {
  return (
    <h6 className={`${styles.CardMatchTitle} ${classes} mt-1 mb-1`}>{text}</h6>
  );
};

export const Button = ({
  text = "",
  classes = "",
  style = {},
  type = "",
  disabled = false,
  onClick = () => { },
}) => {
  return (
    <button
      className={`${styles.Button} ${classes} ${styles.disabled}`}
      style={style}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export const TextDivider = ({ text = "", classes = "" }) => {
  return <div className={`${styles.TextDivider} ${classes}`}>{text}</div>;
};

export const UnorderedList = ({
  lists = [],
  classes = "",
  item_classes = "",
}) => {
  return (
    <ul className={`${styles.UL} ${classes} fs-7`}>
      {lists.map((item, index) => (
        <li key={index} className={`${item_classes} mt-2 mb-2 fs-7`}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export const Checkbox = ({
  label = "",
  secondLabel = "",
  classes = "",
  value = "",
  checked = "",
  onChange = () => { },
}) => {
  const CombineLabels = () => {
    return (
      <span>
        {label}
        <strong> {secondLabel} </strong>
      </span>
    );
  };
  return (
    <Form.Check
      checked={checked}
      value={value}
      type="checkbox"
      className={`${styles.Checkbox} ${classes}`}
      label={<CombineLabels />}
      onChange={onChange}
    />
  );
};

export const InputOTP = ({ props }) => {
  return (
    <Form.Control
      {...props}
      type="text"
      style={{ padding: "25px !important", fontSize: "20px !important" }}
      className={`${styles.Input} ${styles.InputOTP}`}
    // size='lg'
    />
  );
};

export const LiveBadge = ({ text = "Live", classes = "" }) => {
  return <span className={`${styles.LiveBadge} ${classes}`}>{text}</span>;
};

export const Score = ({ text = "", classes = "" }) => {
  return <span className={`${styles.Score} ${classes}`}>{text}</span>;
};


export const Loader = ({ classes = "" }) => {
  return <span className={`w-100 d-flex justify-content-center py-4 text-light ${classes}`}><Spinner /></span>;
};

export const EmptyContainer = ({ text = "Record not found", classes = "" }) => {
  return <p className={`w-100 d-flex fs-4 justify-content-center py-4 text-light ${classes}`}>{text}</p>;
};

export const ImagePlaceholder = ({ classes = "", overlayClasses = "" }) => {
  return <div
    className={`${styles.ImagePlaceholder} ${classes}`}
  >
    <div className={`${styles.overlay} ${overlayClasses} image-placeholder-animate`}>
    </div>
  </div>;
};

export const TagPlaceholder = ({ length = 5, classes = "", tagClasses = "" }) => {
  return <div
    className={`${styles.TagPlaceholder} ${classes}`}
  >
    {
      new Array(length).fill(0).map(item => {
        return <span className={`${styles.tag} ${tagClasses}`}>
        </span>
      })
    }
  </div >
};


export const EmptyThreadContainer = ({ text = "", isLoading = false }) => {
  const STRING = useLocalization()
  return (
    <div className={styles.EmptyThreadContainer}>
      {isLoading ? <Spinner /> :
        <p className="m-0 text-white fs-1">
          {STRING.EmptyInbox}
        </p>
      }
    </div>
  )
}


export const PremiumSign = () => {
  return (
    <div className='premium scale-in-center'>
      <img src={SVG.CROWN_ICON} alt="" />
    </div>
  )
}


export const TemplateSign = ({ text }) => {
  return (
    <div className='templateTag'>
      <span>{text}</span>
    </div>
  )
}


export const PlaySign = () => {
  return (
    <div>
      <img src={SVG.PLAY_ICON} alt="" className='playBtn ' />
    </div>
  )
}

