import React, { useState } from "react";
import { InputGroup, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES, INVALID_FLOAT_INPUT_TYPE, INVALID_NUMBER_INPUT_TYPE } from "../../Constant/InputTypesAndPatternsConstant";
import { Button, Checkbox, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import dayjs from "dayjs";
import _ from "lodash";
import { MdModeEditOutline } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { IMAGE_TYPES, WEB_ROUTES } from '../../Constant/constants';
import { TOAST_MESSAGE } from "../../Constant/Message";
import { useCreateTemplateMutation } from "../../Data/services/dashboardApi";
import { baseRoute } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import TimeFieldComponent from "../CustomComponents/TimeFieldComponent";
import { message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { IMAGES } from "../../Constant/ImageConstant";

const AddWorkoutTemplateModal = ({ handleModalClose }) => {
  const STRING = useLocalization()
  const navigate = useNavigate()
  const { is_connectAccountConnected, stripe_capabilities } = useSelector((state) => state.authInfo.user);

  const [postTemplate] = useCreateTemplateMutation();
  const [modalClass, setModalClass] = useState(
    "addWorkoutTemplateModalContainer scale-in-hor-center"
  );

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedValue, setSelectedValue] = useState("free");

  // const [exercises, setExercises] = useState([{ name: "", details: "" }]);

  //   const [selectedIntensity, setSelectedIntensity] = useState("");
  //   const handleIntensityChange = (value) => setSelectedIntensity(value);
  const optionList = ["Easy Effort", "Moderate Effort", "Intense Effort"];
  //   const handleEditProfile = (value) => setIsEditProfile(value);
  //   const [isEditProfile, setIsEditProfile] = useState(false);

  const [mediaImage, setMediaImage] = useState('');
  const [templateName, setTemplateName] = useState("");
  const [duration, setDuration] = useState(undefined);
  const [templateDescription, setTemplateDescription] = useState("");
  const [calories, setCalories] = useState(undefined);
  const [price, setPrice] = useState(undefined);
  const [sections, setSections] = useState([
    {
      title: "",
      exercises: [
        {
          name: "",
          intensity: "",
          standard_time: "",
          goal_time: "",
          notes: "",
        },
      ],
    },
  ]);


  const beforeUpload = (file) => {
    const isJpgOrPng = IMAGE_TYPES.includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      ErrorToast(STRING.FileFormatRequired, 'error', false);
    }
    else if (!isLt2M) {
      ErrorToast(STRING.ImageSizeRequired, 'error', false);
    }
    return isJpgOrPng && isLt2M;
  };


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };


  // upload files
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: IMAGE_TYPES,
    value: !_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
        setMediaImage(info.file.originFileObj);

      }
      if (status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage('');
    },
  };


  const addNewSection = () => {
    const newSections = _.cloneDeep(sections);
    newSections.push({
      title: "",
      exercises: [
        {
          name: "",
          intensity: "",
          standard_time: "",
          goal_time: "",
          notes: "",
        },
      ],
    });
    setSections(newSections);
  };

  const addNewExercise = (sectionIndex) => {
    const newSections = _.cloneDeep(sections);
    newSections[sectionIndex].exercises.push({
      name: "",
      intensity: "",
      standard_time: "",
      goal_time: "",
      notes: "",
    });
    setSections(newSections);
  };

  const handleInputChange = (e, sectionIndex, exerciseIndex, field) => {
    const newSections = _.cloneDeep(sections);

    if (field === "intensity") {
      newSections[sectionIndex].exercises[exerciseIndex][field] = e;
    } else if (field === "standard_time" || field === "goal_time") {

      const time = new Date(e?.$y, e?.$M, e?.$D, e?.$H, e?.$m, e?.$s);

      newSections[sectionIndex].exercises[exerciseIndex][field] = e;
    } else {
      newSections[sectionIndex].exercises[exerciseIndex][field] =
        e.target.value;
    }

    setSections(newSections);
  };

  const handleSectionTitleChange = (e, sectionIndex) => {
    const newSections = _.cloneDeep(sections);
    newSections[sectionIndex].title = e.target.value;
    setSections(newSections);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  const handleModalCloseWithAnimation = () => {
    setModalClass("addWorkoutTemplateModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  const handleStripeConnect = () => {
    navigate(baseRoute(WEB_ROUTES.PAYMENT))
    handleModalCloseWithAnimation()
  }


  const handleSubmit = async () => {
    if ((selectedValue !== "free") && (!is_connectAccountConnected || !stripe_capabilities)) return;
    if (isSubmitted) return;

    if (_.isEmpty(mediaImage)) return ErrorToast(STRING.TemplateImageRequired)
    if (_.isEmpty(templateName)) return ErrorToast(STRING.TemplateNameRequired);
    if (!duration) return ErrorToast(STRING.TemplateDurationRequired);
    if (!parseInt(duration) || (parseInt(duration) <= 0)) return ErrorToast(STRING.TemplateDurationGreaterThanZero);
    if (_.isEmpty(templateDescription)) return ErrorToast(STRING.TemplateDescriptionRequired);
    // if (!calories) return ErrorToast(STRING.TemplateCaloriesRequired);
    // if (!parseInt(calories) || (parseInt(calories) <= 0)) return ErrorToast(STRING.TemplateCaloriesGreaterThanZero);
    if ((selectedValue === 'paid')) {
      if (!price) return ErrorToast(STRING.TemplatePriceRequired);

      if (!parseFloat(price) || (parseFloat(price) <= 0)) return ErrorToast(STRING.TemplatePriceGreaterThanZero);

    }

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (_.isEmpty(section.title)) return ErrorToast(STRING.SectionTitleRequired);

      const exercises = section.exercises;
      for (let j = 0; j < exercises.length; j++) {
        const exercise = exercises[j];

        if (_.isEmpty(exercise.name)) return ErrorToast(STRING.ExerciseNameRequired);
        if (_.isEmpty(exercise.intensity)) return ErrorToast(STRING.ExerciseIntensityRequired);
        if (_.isEmpty(exercise.standard_time)) return ErrorToast(STRING.ExerciseStandardTimeRequired);
        if (!dayjs(exercise.standard_time).isValid()) return ErrorToast(STRING.InvalidExerciseStandardTime);
        if (_.isEmpty(exercise.goal_time)) return ErrorToast(STRING.ExerciseGoalTimeRequired);
        if (!dayjs(exercise.goal_time).isValid()) return ErrorToast(STRING.InvalidExerciseGoalTime);
      }
    }

    try {

      setIsSubmitted(true)

      const formData = new FormData();
      const payload = {};
      formData.append("image_url", mediaImage);
      formData.append("name", templateName);
      formData.append("duration", parseInt(duration));
      formData.append("description", templateDescription);
      // payload.calories = parseInt(calories);
      formData.append("is_paid", (selectedValue === 'paid') ? "1" : 0)
      formData.append("price", (selectedValue === 'paid') ? parseFloat(price) : 0)

      for (let i = 0; i < sections.length; i++) {
        const section_data = sections[i];
        const section_exercise = section_data.exercises;

        formData.append(`section[${i}][title]`, section_data.title)

        for (let j = 0; j < section_exercise.length; j++) {
          const exercise_data = section_exercise[j];
          formData.append(`section[${i}][exercise][${j}][name]`, exercise_data.name)
          formData.append(`section[${i}][exercise][${j}][intensity]`, exercise_data.intensity)
          formData.append(`section[${i}][exercise][${j}][standard_time]`, dayjs(exercise_data.standard_time).format('LT'))
          formData.append(`section[${i}][exercise][${j}][goal_time]`, dayjs(exercise_data.goal_time).format('LT'))
          formData.append(`section[${i}][exercise][${j}][notes]`, exercise_data.notes)

        }
      }


      const response = await postTemplate(formData);

      if (response.error)
        if (response.error.status === 400) {
          const message = await response.error.data.message;
          Toast(`${message}`, "error");
        }
        else if (response.error.status === 422) {
          const message = await response.error.data.message;
          Toast(`${message}`, "error");
        }
        else {
          throw new Error('SERVER_ERROR');
        }

      if (!response.error && response.data.code === 200) {
        SuccessToast(TOAST_MESSAGE.SUCCESS.TEMPLATE_CREATED);
        handleModalCloseWithAnimation()
      }
    } catch (error) {
      console.log(error)
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast(TOAST_MESSAGE.ERROR.TEMPLATE_CREATION, "error");
      }
    }
    finally {
      setIsSubmitted(false)
    }

  };

  return (
    <div>
      {
        <ModalContainer handleClose={handleModalCloseWithAnimation}>
          <div className={modalClass}>
            <div className="mb-3 d-flex align-items-center w-100 justify-content-between">
              {
                // <p className='fs-4 fw-medium m-0'>
                //     {STRING.TemplateName}
                // </p>
              }
              {
                // MARK: Tamplate Name Editable
              }
              <div className="templateName">
                <input
                  type="text"
                  value={templateName}
                  onChange={(e) => {
                    setTemplateName(e.target.value);
                  }}
                  placeholder={STRING.TemplateName}
                  className="templateNameField"
                />
                <MdModeEditOutline color="#F68B28" />
              </div>

              <div className={"closeBtn"}>
                <FaCircleXmark
                  fontSize={40}
                  color={"#F68B28"}
                  onClick={handleModalCloseWithAnimation}
                />
              </div>
            </div>
            {
              // MARK: DRAGGER 
            }
            <Dragger  {...props} className='w-100' >

              {!_.isEmpty(mediaImage) ?
                <img
                  src={!_.isObject(mediaImage) ? mediaImage : URL.createObjectURL(mediaImage)}
                  alt="avatar"
                  style={{ width: '100%', height: '100px' }}
                  className="img-fluid"
                /> :
                <div>
                  <p className="ant-upload-drag-icon">
                    <img src={IMAGES.UPLOAD_ICON} alt="" />
                  </p>
                  <p className="ant-upload-hint">
                    {STRING.DropImage}
                  </p>
                  <p className="ant-upload-hint">
                    {STRING.ImageSupport}
                  </p>
                </div>
              }

            </Dragger>
            {
              // MARK: Check Boxes
            }
            <div className="w-100 d-flex flex-column align-items-center justify-content-between mt-2">
              <Input
                classes="mx-3"
                placeholder={STRING.Duration}
                type={INPUT_TYPES.NUMBER}
                value={duration}
                onChange={e => setDuration(e.target.value)}
                onKeyDown={(e) => (INVALID_NUMBER_INPUT_TYPE.includes(e.key) && e.preventDefault())}
              />
              <InputGroup>
                <Input
                  classes="mt-2"
                  placeholder={STRING.TemplateDescription}
                  type={INPUT_TYPES.TEXTAREA}
                  rows={2}
                  as={INPUT_TYPES.TEXTAREA}
                  value={templateDescription}
                  onChange={(e) => setTemplateDescription(e.target.value)}
                // onBlur={onBlur}
                />
              </InputGroup>
              {/* <Input
                classes="mx-3"
                placeholder={STRING.Calories}
                type={INPUT_TYPES.NUMBER}
                value={calories}
                onChange={e => setCalories(e.target.value)}
                onKeyDown={(e) => (INVALID_NUMBER_INPUT_TYPE.includes(e.key) && e.preventDefault())}
              /> */}
            </div>
            {
              // MARK: Check Boxes
            }
            <div
              className={`w-100 m-1 d-flex flex-row justify-content-around checkBoxes scale-in-center-slow`}
            >
              <Checkbox
                classes="m-2 "
                label={STRING.Free}
                value="free"
                checked={selectedValue === "free"}
                onChange={handleCheckboxChange}
              />

              <Checkbox
                classes="m-2 "
                label={STRING.Paid}
                value="paid"
                checked={selectedValue === "paid"}
                onChange={handleCheckboxChange}
              />
            </div>
            {
              (selectedValue === 'free') ? "" :
                !is_connectAccountConnected ?
                  <div className="w-100 d-flex flex-column align-items-center w-100 justify-content-start mb-4 text-center">
                    <p className="fs-5 m-0 fs-5 fw-bold label">{STRING.StripeConnect}</p>
                    <p className="fs-8 m-0 description">
                      {STRING.StripeConnectDescription(handleStripeConnect)}
                    </p>
                  </div> :
                  !stripe_capabilities ?
                    <div className="w-100 d-flex flex-column align-items-center w-100 justify-content-start mb-4 text-center">
                      <div className="">
                        <p className="fs-5 m-0 fs-5 fw-bold label">{STRING.IncorrentConnectDetails}</p>
                        <p className="fs-8 m-0 description">
                          {STRING.IncorrentConnectDetailsDescription(handleStripeConnect)}
                        </p>
                      </div>
                    </div>
                    :
                    <div className=" d-flex flex-row align-items-center justify-content-between mb-2">
                      <Input
                        placeholder={STRING.TemplatePrice}
                        type={INPUT_TYPES.NUMBER}
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        onKeyDown={(e) => (INVALID_FLOAT_INPUT_TYPE.includes(e.key) && e.preventDefault())}
                      />
                    </div>
            }

            {
              //MARK: SECTION
            }

            {sections.map((section, sectionIndex) => (
              <div className="sectionArea" key={sectionIndex + 1}>
                <div className="fs-7 fw-bold mb-2 w-100">
                  <input
                    type="text"
                    value={section.title}
                    placeholder={STRING.SectionName}
                    onChange={(e) => handleSectionTitleChange(e, sectionIndex)}
                    className="sectionNameField"
                  />{" "}
                  <MdModeEditOutline color="#F68B28" />
                </div>

                {section.exercises.map((exercise, exerciseIndex) => (
                  <>
                    {exerciseIndex > 0 ? <hr></hr> : ""}

                    <InputGroup>
                      <Input
                        classes="bg-darkJungleGreen"
                        placeholder={STRING.ExerciseName}
                        type={INPUT_TYPES.TEXT}
                        value={exercise.name}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            sectionIndex,
                            exerciseIndex,
                            "name"
                          )
                        }
                      // onBlur={onBlur}
                      />
                      {
                        // <div className="input-before-icon" >
                        //     <CiUser />
                        // </div>
                      }
                    </InputGroup>

                    <SelectFieldComponent
                      //   firstOption={v}
                      optionsList={optionList}
                      //   onSelectChange={handleIntensityChange}
                      // disabled={isEditProfile ? false : true}
                      firstOption={
                        exercise.intensity !== ""
                          ? exercise.intensity
                          : STRING.SelectIntensity
                      }
                      onSelectChange={(e) =>
                        handleInputChange(
                          e,
                          sectionIndex,
                          exerciseIndex,
                          "intensity"
                        )
                      }
                      className={"select bg-darkJungleGreen"}
                    />

                    <TimeFieldComponent
                      placeholder={STRING.StandardTime}
                      //   onChange={handleChangeStandardTime}
                      // value={exercise.standard_time}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          sectionIndex,
                          exerciseIndex,
                          "standard_time"
                        )
                      }
                    />

                    <TimeFieldComponent
                      placeholder={STRING.GoalTime}
                      //   onChange={() => {}}
                      // value={exercise.goal_time}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          sectionIndex,
                          exerciseIndex,
                          "goal_time"
                        )
                      }
                    />

                    <InputGroup>
                      <Input
                        classes="bg-darkJungleGreen mt-1"
                        placeholder={STRING.Note}
                        type={INPUT_TYPES.TEXTAREA}
                        rows={2}
                        as={INPUT_TYPES.TEXTAREA}
                        value={exercise.notes}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            sectionIndex,
                            exerciseIndex,
                            "notes"
                          )
                        }
                      // onBlur={onBlur}
                      />
                    </InputGroup>
                  </>
                ))}

                {
                  // MARK: ADD EXERCISE BUTTON
                }

                <Button
                  classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-6 exerciseBtn "
                  // text={` ÷ ${STRING.AddExercise}`}
                  text={
                    <span className=" d-flex justify-content-center align-items-center">
                      <FaPlus color="#F68B28" className="mx-1" />
                      <span className="">{` ${STRING.AddExercise}`}</span>
                    </span>
                  }
                  type={INPUT_TYPES.SUBMIT}
                  onClick={() => addNewExercise(sectionIndex)}
                />
              </div>
            ))}

            <div className="w-100">
              <Button
                classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-6 exerciseBtn"
                text={
                  <span className=" d-flex justify-content-center align-items-center">
                    <FaPlus color="#F68B28" className="mx-1" />
                    <span className="">{` ${STRING.Section}`}</span>
                  </span>
                }
                type={INPUT_TYPES.SUBMIT}
                onClick={addNewSection}
              />

              <hr></hr>

              <Button
                classes="mt-3 mb-2 rounded-4 scale-in-center-slow fs-5"
                text={
                  <div className='d-flex align-items-center justify-content-center'>
                    {STRING.PublishTemplate}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                  </div>
                }
                type={INPUT_TYPES.SUBMIT}
                onClick={handleSubmit}
                disabled={(selectedValue !== "free") && (!is_connectAccountConnected)}
              />
            </div>
          </div>
        </ModalContainer>
      }
    </div>
  );
};

export default AddWorkoutTemplateModal;
