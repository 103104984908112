import React from "react";
import { SVG } from "../../../Constant/SvgConstant";

const HorizontalImageWithGradient = ({ image = '', video = "", thumbnail = "", is_mine = false, is_paid = false, is_purchased = false }) => {
  return (
    <div className="imageWrapper ">
      {thumbnail !== "" ? (
        <div className="w-100 h-100 thumbnail-container">
          <img src={thumbnail} alt="" className={`image`} />
          <span >
            <img src={SVG.PLAY_ICON} alt="Play Sign" className="play-icon" />
          </span>
          <div className="gradientOverlay"></div>
        </div>

      ) : (
        <>
          <img src={image} alt="" className={`image ${(!is_mine && is_paid && !is_purchased) ? 'blur' : ""}`} />
          <div className="gradientOverlay"></div>
        </>
      )
      }
    </div >
  );
};

export default HorizontalImageWithGradient;
