import moment from "moment";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { GET_TRAINING_LOG_PAYLOAD_FORMAT, LOG_TYPE_ENUM } from "../../../Constant/constants";
import { useGetTrainingLogQuery } from "../../../Data/services/trainingApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import _ from "lodash";
import LineChart from "../../../Components/Chart/LineChart";

const WeeklyCalendar = ({ children }) => {
  const STRING = useLocalization()

  const [isViewAnalytics, setViewAnalytics] = useState(false);
  const [week, setWeek] = useState(moment().startOf('week'));

  const { data, isFetching } = useGetTrainingLogQuery({ type: LOG_TYPE_ENUM.WEEK, date: moment(week).format(GET_TRAINING_LOG_PAYLOAD_FORMAT) }, {
    refetchOnMountOrArgChange: true
  })

  const handlePrevWeek = () => {
    setWeek(prev => moment(prev).subtract(1, 'weeks'))
  }

  const handleNextWeek = () => {
    setWeek(prev => moment(prev).add(1, 'weeks'))
  }

  const handleViewAnalytics = () => {
    setViewAnalytics(true)
  }

  const handleViewGrid = () => {
    setViewAnalytics(false)
  }


  return (
    <>
      <div className=" d-flex flex-row flex-wrap justify-content-center  align-items-center justify-content-sm-between">
        <div className="left-side p-sm-0 px-5">
          {`${moment(week).format('DD')} ${STRING.MonthNames[moment(week).month()]} - ${moment(week).endOf('week').format('DD')} ${STRING.MonthNames[moment(week).endOf('week').month()]}`}
        </div>
        <div className="right-side d-flex flex-row">
          {_.isEmpty(data?.data) ? "" :
            isViewAnalytics ?

              <button className="viewChart" onClick={handleViewGrid}>
                View Grid
              </button> :
              <button className="viewChart" onClick={handleViewAnalytics}>
                View Analytics
              </button>
          }
          <div className="align-items-center d-flex flex-row justify-content-between text-center mx-2">
            <span className="left-arrow cursor-pointer" onClick={handlePrevWeek}>
              <MdKeyboardArrowLeft />
            </span>
            <span className="d-flex flex-column center-text">
              {`${STRING.Week} ${moment(week).isoWeek()}`}
              <span className="w-100  fs-7 mx-auto">
                {moment(week).year()}
              </span>
            </span>
            <span className="right-arrow cursor-pointer" onClick={handleNextWeek}>
              <MdKeyboardArrowRight />
            </span>
          </div>
          {children}
        </div >
      </div >
      <div className="mt-3">
        {
          isViewAnalytics ?
            isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> :
              _.isEmpty(data?.data) ? "" :
                <LineChart type="week" data={data?.data} data_value={week} /> :
            STRING.DaysName.map((item, index) => (
              <div className="weekly-view focus-in-expand ">
                <span>{item}</span>
                <div className="ms-auto d-flex flex-row align-items-center">
                  {isFetching ? <Spinner size="sm" /> : <span>{`${parseFloat(data?.data[index]?.time_period).toFixed(2) || 0} ${STRING.Mins}`}</span>}
                </div>
              </div>
            ))
        }
      </div>
    </>

  );
};

export default WeeklyCalendar;
