import React from "react";
import { useSelector } from "react-redux";
import { SubTitle } from "../../Components/CustomComponents";
import { useLocalization } from "../../Hooks/useLocalization";
import { ROUTES } from "./Route";

const Privacy = ({ setPage }) => {
  const STRING = useLocalization()
  const handleGotoTermAndCondition = () => {
    setPage(ROUTES.TERMS);
  };

  const privacyData = useSelector((state) => state.staticContent.privacy);

  return (
    <div className="Privacy focus-in-expand-slow">
      <SubTitle text={STRING.PrivacyPolicy} />
      <br></br>

      {privacyData === null ? (
        <h1 class="shimmer">{STRING.Loading}</h1>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: privacyData?.text }} />
      )}
      <br></br>

      <div className="termsBtn" onClick={handleGotoTermAndCondition}>
        <p>{STRING.TermsAndConditionButtonText}</p>
      </div>
      <br></br>
    </div>
  );
};

export default Privacy;
