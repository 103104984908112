import React from 'react'
import { Description } from '../../../Components/CustomComponents'
import { useLocalization } from '../../../Hooks/useLocalization'

const LoginLink = ({ handleLoginClick }) => {
    const STRING = useLocalization()
    return (

        <div
            className='d-flex justify-content-center flex-direction-row mt-1 mb-4'
        >
            <div
                className='d-flex justify-content-center flex-direction-row tracking-in-expand-fwd-bottom'
            >
                <Description
                    text={STRING.AlreadyMember}
                    classes="fs-7"
                />
                <Description
                    classes="link-text fs-6 ps-1 fw-bold"
                    text={STRING.Login}
                    onClick={handleLoginClick}
                />

            </div>
        </div>

    )
}

export default LoginLink
