import React from "react";
import { ACCOUNT_ENUM } from "./Route";

import { Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

// Custom Component
import { Button, Description, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";

// import { useGetAllUsersQuery } from "../../services/userApi";
// import { updateUsers } from "../../Features/userSlice";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import { WEB_ROUTES } from "../../Constant/constants";
import { IMAGES } from "../../Constant/ImageConstant";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import { storeToken } from "../../Data/services/localStorageService";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";

import Logo from "../../Components/CommonComponents/Logo";
import { LoginPayload } from "../../Constant/PayloadConstant/LoginPayloadConstant";
import SignUpLink from "./Components/SignUpLink";
import WelcomeBack from "./Components/WelcomeBack";
import { useLocalization } from "../../Hooks/useLocalization";

export default function Login({ setPage }) {
  const STRING = useLocalization()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { lookupData, isFetching, refetch } = useGetLookupQuery({
  //   refetchOnMountOrArgChange: true,
  // });
  // console.log(lookupData);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  // const handleSubmit = () => {
  //     dispatch(updateLogin(true))
  // }

  // const { data, isSuccess, isLoading, isFetching, refetch } =
  //     useGetAllUsersQuery({ refetchOnMountOrArgChange: true });

  // var userData = useSelector((state) => state.user.users);
  // console.log(`userData = ${userData}`);
  // useEffect(() => {
  //     // setRowData(userData);
  // }, [userData]);

  // useEffect(() => {
  //     if (data && isSuccess) {
  //         console.log("isSuccess = " + data.users);
  //         dispatch(updateUsers(data.users));
  //     }
  // }, [isSuccess, data, dispatch]);

  const [loginUser, { isError, isLoading, isSuccess }] = useLoginUserMutation();

  const handleLogin = async (data) => {
    data.device_type = "web";
    data.device_token = "123";

    console.log("handle Login");
    console.log(data);

    // todo: for Alpha
    // storeToken("testing_token");
    // navigate(WEB_ROUTES.DASHBOARD);

    try {
      const response = await loginUser(data);

      // console.log(response.error.status);
      console.log(response);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.code === 200) {
        const token = response.data.data.api_token;

        storeToken(token);
        SuccessToast(response?.data?.message);
        navigate(WEB_ROUTES.DASHBOARD);
      }
    } catch (error) {
      console.log(error);
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout leftSideImage={IMAGES.LOGIN}>
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : errors?.password?.message
            ? Toast(errors?.password?.message, "error")
            : null
        : ""}
      <Logo />
      <WelcomeBack />
      <Form
        className="w-100 scale-in-center"
        onSubmit={handleSubmit(handleLogin)}
      >
        <Controller
          control={control}
          name={LoginPayload.Email}
          rules={{
            required: STRING.EmailRequired,
            pattern: {
              value: INPUT_PATTERNS.EMAIL_PATTERNS,
              message: STRING.ValidEmailRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={LoginPayload.Password}
          rules={{
            required: STRING.PasswordRequired,
            minLength: {
              value: 6,
              message: STRING.PasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.PasswordPatternRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.Password}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <div className="w-100 d-flex justify-content-between align-items-center">
          {
            //     <Checbox
            //     label='Keep me login'
            //     classes="mt-2 mb-2 "
            // />
          }
          <Description
            classes="ms-auto cursor-pointer"
            text={STRING.ForgetPassword}
            onClick={handleForgotPasswordClick}
          />
        </div>

        <br></br>

        <Button
          classes="mt-3 mb-2 scale-in-center-slow"
          text={isLoading ? STRING.Sending : STRING.Login}
          type={INPUT_TYPES.SUBMIT}
          disabled={isLoading}
        />
      </Form>
      <SignUpLink handleSignUpClick={handleSignUpClick} />
      {
        // <Description
        //   classes="m-2 fs-6 text-center tracking-in-expand-fwd-bottom-slow"
        //   text={STRING.ContinueWithSocialLogin}
        //   secondText={STRING.TermsAndCondition}
        // />
        // <SocialButtons />
      }
    </AccountLayout>
  );
}
