import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { EmptyContainer } from "../../../Components/CustomComponents";
import EditWorkoutTemplateModal from "../../../Components/Modals/EditWorkoutTemplateModal";
import { SVG } from "../../../Constant/SvgConstant";
import { useGetMyTemplateQuery } from "../../../Data/services/userApi";
import { useLocalization } from "../../../Hooks/useLocalization";
import { ROUTES } from "../Route";
import styles from "../styles/index.module.css";



export default function MyTemplate({ setPage }) {
  const STRING = useLocalization()
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [editTemplateData, setEditTemplateData] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);


  const { data, isFetching } = useGetMyTemplateQuery({ page: currentPage, limit: 18, offset }, {
    refetchOnMountOrArgChange: true,
  });

  const listingData = useSelector(state => state.user.my_template.data)


  // MARK: USE EFFECT
  useEffect(() => {
    if (isFetching) return;
    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView])

  const handleTemplateView = (details) => {
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_TEMPLATE : ROUTES.TEMPLATE,
      prev_route: STRING['MyTemplate'],
      data: details
    });
  };


  const handleEditTemplateOpen = (e, item) => {
    setEditTemplateData(item)
    e.stopPropagation()
  }

  const handleEditTemplateClose = () => {
    setEditTemplateData(undefined)
  }


  return (
    <>
      {_.isUndefined(editTemplateData) ? "" : <EditWorkoutTemplateModal template_slug={editTemplateData.slug} data={editTemplateData} handleModalClose={handleEditTemplateClose} />}
      {(_.isEmpty(listingData) && !isFetching) ? <EmptyContainer text={STRING.TemplateNotFound} /> :
        <Row className="gy-3">
          {
            (listingData ?? []).map((item, index) => (
              <Col
                md={6}
                xs={12}
                key={item.slug}
                style={{ position: "relative", zIndex: 1 }}
                className="mb-4 mt-5"
                ref={
                  (listingData.length >= (total - offset)) ? null :
                    (index === (listingData.length - 1)) ? ref : null}
                onClick={() => handleTemplateView(item)}
              >
                <Templates item={item} handleEditTemplate={(e) => handleEditTemplateOpen(e, item)} />
                <div className={styles.title_badge}>{STRING.Template} {index + 1}</div>
              </Col>
            ))
          }
        </Row>
      }
      {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}
    </>
  );
}

const Templates = ({ item, handleEditTemplate }) => {

  const handleViewPage = () => {
    if ("isPremium") {
      // openPremiumTemplate();
    } else {
      // openFreeTemplate();
    }
  };


  return (
    <div
      className={`templateContainer ${styles.template_container}`}
      onClick={handleViewPage}
      role="button"
    >
      {
        !item?.image_url ? "" :
          <div className='imageWrapper'>
            <img src={item.image_url} alt="" className={`image`} />
            <div className='gradientOverlay'></div>
          </div>
      }
      <div className={`textContainer d-flex align-items-center gap-4 `}>
        <div>
          <h3 className="title lc-1 m-0 fs-4 flex-wrap mb-1">{item.name}</h3>
          <p className={`lc-2 m-0 fs-6 ${styles.description}`}><span className="fw-bold me-2">Duration : </span>{item.duration} mins</p>
          {/* <p className={`lc-2 m-0 fs-6 ${styles.description}`}><span className="fw-bold me-2">Calories : </span>{item.calories}</p> */}
        </div>
        <div>
          <div className={`scale-in-center ${styles.edit_icon}`} onClick={handleEditTemplate}>
            <img src={SVG.EDIT_ICON} alt="" />
          </div>
        </div>
      </div>
    </div >
  );
};
