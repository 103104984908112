const ROUTES = {
  PROFILE: "PROFILE",
  POST: "POST",
  WORKOUT: "WORKOUT",
  PREMIUM_POST: "PREMIUM_POST",
  PREMIUM_WORKOUT: "PREMIUM_WORKOUT",
  MESSAGE: "MESSAGE",
};

export { ROUTES };
