import React, { useMemo, useState } from 'react';
import { LuShare2 } from "react-icons/lu";
import { useSelector } from 'react-redux';
import UserAvatarWithNameAndTag from '../../../Components/CustomComponents/UserAvatarWithNameAndTag';
import ShareForumModal from '../../../Components/Modals/ShareForumModal';
import { getTime } from '../../../Helper';
import { useLocalization } from '../../../Hooks/useLocalization';
import ForumComment from './ForumComment';

const CommunityPostWithComment = ({ communityPost: data }) => {
    const STRING = useLocalization()
    const [showComments, setShowComments] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const user = useSelector(state => state.authInfo.user)

    const communityPost = useMemo(() => {
        return !!data.is_shared ? data.shared_forum : data
    }, [])

    const handleShowShareModal = () => {
        setShowShareModal(true);
    };

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    };

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    return (
        <div>

            {showShareModal ? (
                <ShareForumModal data={communityPost} handleModalClose={handleCloseShareModal} />
            ) : (
                ""
            )}

            <div className='Community-Post'>
                <ConditionalWrap
                    condition={data.is_shared}
                    data={data}
                >
                    <div className='p-1'>

                        <div className="d-flex flex-md-row flex-column justify-content-between w-100">
                            <p className='m-0 fw-bold '>{"communityPost.title divdivdiv divd ivdi vdiv divdivdi vdiv divdivdi vdivdivdivdivdivd ivdivdivdivdivdiv"}</p>
                            <p className='m-0 my-sm-1 my-md-0 post-date text-granite text-nowrap'>{getTime(communityPost.createdAt)}</p>
                        </div>

                        <div className='d-flex flex-row justify-content-between w-100 my-3'>
                            <UserAvatarWithNameAndTag userName={communityPost.user_name} userTag={''} avatarImage={communityPost.user_image} />
                        </div>

                        {!communityPost?.media_url ? "" : <div className='post-image my-2'>
                            <img src={communityPost.media_url} alt="" />
                        </div>}

                        <div className='d-flex flex-wrap'>
                            <p className='m-0 text-granite'>{communityPost.body}</p>
                            <p className="m-0 mb-2 fs-6 text-granite">
                                {communityPost.description}
                            </p>
                        </div>

                        <div className='d-flex flex-wrap justify-content-between' >
                            <p className='m-0 my-2 fw-bold primary-text cursor-pointer' onClick={toggleComments} >
                                {!showComments ? STRING.CommunityPostCommentView(communityPost.comments) : STRING.CommunityPostCommentHide}
                            </p>
                            {
                                (communityPost.user_slug === user.slug) ? '' :
                                    <p className='m-0 my-2 fw-bold cursor-pointer' onClick={handleShowShareModal} > <LuShare2 /> Share </p>
                            }
                        </div>


                    </div>
                </ConditionalWrap>
                {!showComments ? "" :
                    <div className={`commentsArea show ' `} >
                        <ForumComment key={data.is_shared ? data.slug : communityPost.slug} forum_slug={data.is_shared ? data.slug : communityPost.slug} />
                    </div>
                }
            </div>

        </div>

    )
}

export default CommunityPostWithComment


const ConditionalWrap = ({ condition, data, children }) => (
    !condition ? children :
        <>
            <div className='d-flex flex-row justify-content-between w-100'>
                <UserAvatarWithNameAndTag userName={data.user_name} userTag={''} avatarImage={data.user_image} />
                <p className='m-0 post-date text-granite'>{getTime(data.createdAt)}</p>
            </div>
            {!data.description ? "" :
                <div className='d-flex flex-wrap'>
                    <p className="mt-3 mb-0 fs-6 text-granite">
                        {data.description}
                    </p>
                </div>
            }
            <hr style={{ width: "100%" }} />
            <div className='px-2'>
                {children}
            </div>

        </>
);
