import React from 'react'
import { SubTitle } from '../../../Components/CustomComponents'
import { useLocalization } from '../../../Hooks/useLocalization'

const SetNewPasswordHeading = () => {
    const STRING = useLocalization()
    return (
        <div className='w-100'>
            <SubTitle
                text={STRING.SetNewPassword}
                classes="mb-1 w-100 fw-bold focus-in-expand"
            />
            <p
                className="w-100 m-0 fw-light fs-7 mb-4 tracking-in-expand-fwd-bottom-fast"
                style={{}}
            >
                {STRING.PleaseEnterYourNewPassword}
            </p>
        </div>
    )
}

export default SetNewPasswordHeading
