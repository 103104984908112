import { createSlice } from "@reduxjs/toolkit";
import { cardApi } from "../services/cardApi";

const initialState = {
    isLoading: true,
    data: []
}


export const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(cardApi.endpoints.getCard.matchFulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.data = payload.data;
            });

        builder
            .addMatcher(cardApi.endpoints.addCard.matchFulfilled, (state, { payload }) => {
                state.data = [...state.data, payload.data];
            });

        builder
            .addMatcher(cardApi.endpoints.removeCard.matchFulfilled, (state, { payload, meta }) => {
                const slug = meta.arg.originalArgs || ''
                state.data = state.data.filter(item => item.slug !== slug)
            });
    },
})


export const { } = cardSlice.actions;

export default cardSlice.reducer;
