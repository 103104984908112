import React, { useState } from 'react';
import { ROUTES } from './Route';

import Community from './Community';
import PostView from "../../Components/PostDetails/PostView";
import PostViewPremium from "../../Components/PostDetails/PostViewPremium";

export default function DASHBOARD() {
    const [page, setPage] = useState({
        route: ROUTES.COMMUNITY,
        data: {}
    });

    const handleBackButtonClick = () => {
        setPage({
            route: ROUTES.COMMUNITY,
            data: {}
        })
    }


    switch (page.route) {
        case ROUTES.COMMUNITY:
            return <Community setPage={setPage} />
        case ROUTES.POST_VIEW:
            return <PostView data={page.data} handleBackButtonClick={handleBackButtonClick} />
        case ROUTES.POST_VIEW_PREMIUM:
            return <PostViewPremium data={page.data} handleBackButtonClick={handleBackButtonClick} />
        default:
            return <Community setPage={setPage} />
    }


}

