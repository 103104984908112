// Need to use the React-specific entry point to import createApi
import { END_POINTS } from "../../Constant/EndPointsContants";
import { baseApi } from "./baseApi";


export const paymentApi = baseApi.injectEndpoints({
    mode: "no-cors",
    endpoints: (builder) => ({
        createPayment: builder.mutation({
            query: (data) => {
                return {
                    url: END_POINTS.PAYMENT.CREATE,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            invalidatesTags: ['Profile/Purchase'],
        }),
    }),
});


export const {
    useCreatePaymentMutation,
} = paymentApi