import { message, Upload } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { INITIAL_TOKEN, UPLOAD_URL } from "../../config/config";
import { IMAGES } from "../../Constant/ImageConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { useSocket } from "../../Context/SocketContext";
import { useGetAllUsersQuery } from "../../Data/services/userApi";
import { useLocalization } from "../../Hooks/useLocalization";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import MemberSelectionCard from "./MemberSelectionCard";
import { IMAGE_TYPES } from "../../Constant/constants";

const { Dragger } = Upload;

const CreateGroupModal = forwardRef(({ handleModalClose }, componentRef) => {
  const STRING = useLocalization()
  const LIMIT = useMemo(() => 20, []);
  const socket = useSocket()

  useImperativeHandle(componentRef, () => {
    return {
      successCB: () => {
        handleModalCloseWithAnimation();
      }
    }
  });

  const { isSocketConnected } = useSelector(state => state.authInfo);
  const [modalClass, setModalClass] = useState(
    "createGroupModalContainer scale-in-hor-center"
  );

  const [selectedMembers, setSelectedMembers] = useState([]);

  const { ref, inView } = useInView({
    threshold: 0,
  });
  const groupNameRef = useRef();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(false)
  const [isFetchedFirstTime, setIsFetchedFirstTime] = useState(false)
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data, isFetching } = useGetAllUsersQuery({ page: currentPage, search, limit: LIMIT, offset }, {
    skip: skip,
    refetchOnMountOrArgChange: false,
  });

  const listingData = useSelector(state => state.user.users.data)

  useEffect(() => {
    if (isFetching) return;
    setIsFetchedFirstTime(true);
    setSkip(true)

    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
      setSkip(false)
    }
  }, [inView])

  useEffect(() => {
    if (!isFetchedFirstTime) return;
    const timeout = setTimeout(() => {
      setCurrentPage(1)
      setOffset(0)
      setTotal(0)
      setSkip(false)
    }, 400)

    return () => clearTimeout(timeout)
  }, [search])


  useEffect(() => {

    if (isSocketConnected) {
      socket.on('createGroupError_', (error) => {
        ErrorToast(error.message)
        setIsSubmitted(false);
      })

    }
    return () => {
      if (isSocketConnected) {
        socket.dispose('createGroupError_')
      }
    }

  }, [isSocketConnected])



  const handleSelect = (id) => {
    setSelectedMembers((prevSelectedMembers) => {
      if (_.includes(prevSelectedMembers, id)) {
        return _.without(prevSelectedMembers, id);
      } else {
        return _.concat(prevSelectedMembers, id);
      }
    });
  }


  const [mediaImage, setMediaImage] = useState("");

  const handleModalCloseWithAnimation = () => {
    setModalClass("createGroupModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  // MARK: UPLOAD IMAGE

  const beforeUpload = (file) => {
    const isJpgOrPng = IMAGE_TYPES.includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast(STRING.FileFormatRequired, "error", false);
    } else if (!isLt2M) {
      Toast(STRING.ImageSizeRequired, "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // upload files
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: IMAGE_TYPES,
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };


  const handleSubmit = async () => {
    if (isSubmitted) return;

    const error = {
      ...(!groupNameRef.current.value ? { groupName: STRING.GroupNameRequired } : {}),
      ...(!mediaImage ? { mediaImage: STRING.GroupImageRequired } : {}),
      ...(!selectedMembers?.length ? { selectedMembers: STRING.AtLeastOneMemberRequired } : {}),
    }

    if (Object.keys(error)?.length) {
      setErrors(error)
      return
    }

    setErrors({})
    setIsSubmitted(true)

    try {

      const formData = new FormData()
      formData.append('file', mediaImage);
      const res = await axios({
        method: "POST",
        url: UPLOAD_URL,
        data: formData,
        headers: {
          "Token": INITIAL_TOKEN,
          "Content-Type": "multipart/form-data"
        },
      })

      const payload = {}
      payload.image_url = res?.data?.data?.link || '';
      payload.title = groupNameRef.current.value
      payload.members = selectedMembers
      socket.emit('_createGroup', payload)

    } catch (err) {
      ErrorToast(STRING.ImageUploadFailed);
      setIsSubmitted(false)
    }



  }


  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <p className="fs-4 fw-medium m-0">{STRING.CreateTeam}</p>

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>

          <p className="m-0 me-auto fs-8 text-granite ">
            {STRING.CreateTeamMsg}
          </p>

          <br></br>

          {
            // MARK: DRAGGER
          }
          <Dragger Dragger {...props} className="w-100">
            {!_.isEmpty(mediaImage) ? (
              <img
                src={
                  !_.isObject(mediaImage)
                    ? mediaImage
                    : URL.createObjectURL(mediaImage)
                }
                alt="avatar"
                style={{ width: "100%", height: "100px" }}
                className="img-fluid"
              />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <img src={IMAGES.UPLOAD_ICON} alt="" />
                </p>
                <p className="ant-upload-text">
                  {STRING.DropImage}
                </p>
                <p className="ant-upload-hint">
                  {STRING.ImageSupport}
                </p>
              </div>
            )}
          </Dragger>
          {errors?.mediaImage ? <p className="error_message mt-1">{errors.mediaImage}</p> : ""}
          <br></br>

          <InputGroup className="mb-2 d-flex flex-column">
            <Form.Control
              type={INPUT_TYPES.TEXT}
              placeholder={STRING.TeamName}
              className={'messageBar input-outline-orange px-3 mt-0 w-100'}
              ref={groupNameRef}
            />
            {errors?.groupName ? <p className="error_message me-auto mt-1 mb-1">{errors.groupName}</p> : ""}
          </InputGroup>

          <div className="w-100 d-flex flex-column  my-2 mb-4">
            <div className="d-flex flex-row justify-content-start w-100">
              <p className="counter w-fit-content">{selectedMembers.length} {STRING.MembersLabel} </p>
            </div>
            {errors?.selectedMembers ? <p className="error_message me-auto mt-1 mb-1">{errors.selectedMembers}</p> : ""}
          </div>
          <div className="users-container">
            {
              (listingData ?? []).map((member, index) => (
                <>
                  {index > 0 ? <hr></hr> : ""}
                  <div
                    ref={
                      (listingData.length >= (total - offset)) ? null :
                        (index === (listingData.length - 1)) ? ref : null}>
                    <MemberSelectionCard
                      key={index}
                      isSelected={_.includes(selectedMembers, member.slug)}
                      onSelect={() => handleSelect(member.slug)}
                      data={member}
                    />
                  </div>

                </>
              ))
            }
            {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}

          </div>

          <Button
            classes="scale-in-center-slow fs-6 mt-4 rounded-4"
            text={<div className='d-flex align-items-center justify-content-center'>
              {STRING.CreateGroupBtnTxt}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
            </div>}
            type={INPUT_TYPES.SUBMIT}
            onClick={handleSubmit}
          />
        </div>
      </ModalContainer>
    </div>
  );
});

export default CreateGroupModal;
