import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BackButton, EmptyContainer } from "../../Components/CustomComponents";
import UserAvatarWithNameAndTag from "../../Components/CustomComponents/UserAvatarWithNameAndTag";
import { POST_TYPE_ENUM, WEB_ROUTES } from "../../Constant/constants";
import { IMAGES } from "../../Constant/ImageConstant";
import { useGetPostDetailsQuery } from "../../Data/services/dashboardApi";
import { baseRoute } from "../../Helper";
import { useLocalization } from "../../Hooks/useLocalization";
import GridCard from "./Components/GridCard";
import HorizontalImageWithGradient from "./Components/HorizontalImageWithGradient";
import { ROUTES } from "./Route";

const PostViewPremium = ({ data = {}, setPage }) => {
  const STRING = useLocalization()
  const navigate = useNavigate()
  const [isImage] = useState(data.type === POST_TYPE_ENUM.IMAGE);

  const { data: details, isFetching } = useGetPostDetailsQuery(data.slug);

  const postData = useMemo(() => {
    if (_.isEmpty(details) && _.isEmpty(data)) return {};
    return (
      {
        ...(data ? data : {}),
        ...(details?.data ? details.data : {})
      }
    )

  }, [details])


  const handleBackButtonClick = () => {
    setPage({
      route: ROUTES.DASHBOARD,
      data: {}
    });
  };


  const handlePurchase = () => {
    navigate(
      baseRoute(WEB_ROUTES.PURCHASE),
      {
        state: {
          data: {
            type: data?.type,
            ...data
          }
        }
      })
  }

  return (
    <div className="PostView focus-in-expand-slow">
      <div className="headerContainer">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <BackButton
            classes="me-3"
            isRelative={true}
            handleBackButton={handleBackButtonClick}
          />
        </div>
      </div>

      {isImage ? (
        <HorizontalImageWithGradient
          image={postData.image_url}
          is_paid={true}
          is_purchased={postData.is_mine || !!postData.payment}
        />
      ) : (
        <HorizontalImageWithGradient
          video={postData.video_url}
          thumbnail={postData.thumbnail_url}
          is_paid={true}
          is_purchased={postData.is_mine || !!postData.payment}
        />
      )}

      <div className="my-4">
        <div className="d-flex flex-row flex-wrap justify-content-between my-2 tags">
          <p className="m-0 fs-3 fw-bold text-white">
            {postData.caption}
          </p>
          {postData?.is_mine ? "" :
            isFetching ? < p
              className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100"
            >
              <Spinner size="sm" />
            </p> :

              postData?.payment ?
                <p className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100">
                  Purchased
                </p> :

                <p className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100 cursor-pointer" onClick={handlePurchase}>
                  ${postData?.price} | {STRING.PurchasePost}
                </p>
          }

        </div>
        {!postData.name ?
          (isFetching ? <span className="text-light ps-2 my-1"><Spinner size="lg" /></span> : "") :
          <div className="d-flex flex-row flex-wrap justify-content-start ">
            <UserAvatarWithNameAndTag
              avatarImage={postData.user_image || IMAGES.PROFILE_IMAGE}
              userName={postData.name}
              userTag="@Tatiana_w"
            />
          </div>
        }
        <hr></hr>
        <p className="m-0 fw-bold fs-4 text-white">{STRING.SimilarPostLabel}</p>
        {_.isEmpty(postData?.similar_videos) ?
          (isFetching ? <div className="w-100 text-light py-3 d-flex justify-content-center"><Spinner size="lg" /></div> :
            <EmptyContainer text={STRING.SimilarPostNotFound} classes="w-100 py-3 text-white" />
          ) :
          <Row>
            {postData.similar_videos.map((item, index) => {
              return <Col
                lg={4}
                md={4}
                sm={6}
                className="mt-2 mb-2 focus-in-expand-slow"
                key={index}
              >
                <GridCard
                  data={item}
                  setPage={setPage}
                />
              </Col>
            }

            )}
          </Row>
        }
      </div>
    </div>
  );
};

export default PostViewPremium;
