//MARK: INPUT TYPES
export const INPUT_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    TEXTAREA: 'textarea',
    EMAIL: 'email',
    PASSWORD: 'password',
    FULL_NAME: 'full_name',
    TIME: 'time',
    SUBMIT: 'submit',
}

//MARK: INPUT PATTERNS
export const INPUT_PATTERNS = {
    EMAIL_PATTERNS: /\S+@\S+\.\S+/,
    ATLEAST_8_CHARACTER_INCLUDING_ALPHABETS_PATTERNS: /^(?=.*[a-zA-Z]).{8,}$/,
    ATLEAST_1_NUMBER_PATTERNS: /^(?=.*[0-9]).*$/,
    ATLEAST_1_SPECIAL_CHARACTER_PATTERNS: /[!@#$%^&*_]/,
    PASSWORD_PATTERNS: /^(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{8,16}$/,
}

export const INVALID_NUMBER_INPUT_TYPE = [
    "-",
    "+",
    "e",
    "E",
    "."
]

export const INVALID_FLOAT_INPUT_TYPE = [
    "-",
    "+",
    "e",
    "E",
]