import React, { useState } from 'react';
import { useLocalization } from '../../Hooks/useLocalization';
import CommunityFollowingPost from './Components/CommunityFollowingPost';
import CommunityPost from './Components/CommunityPost';
import CustomNavbar from './Components/CustomNavbar';
import ForYouPost from './Components/ForYouPost';

const Community = ({ setPage }) => {
    const STRING = useLocalization()
    const [activeLink, setActiveLink] = useState(STRING.Following);

    const handleChangeActive = (link) => {
        console.log(link)
        setActiveLink(link);
    };

    return (
        <div className='Community' >

            {
                // MARK: COMMUNITY NAVBAR
            }
            <div className='Community-Navbar'>
                <CustomNavbar activeLink={activeLink} handleChangeActive={handleChangeActive} />
            </div>

            {
                // MARK: COMMUNITY POSTS
            }

            <div className='pb-4 custom-scrollbar'>

                {
                    activeLink === STRING.Following ?
                        <CommunityFollowingPost setPage={setPage} />
                        : ''

                }

                {
                    activeLink === STRING.ForYou ?
                        <ForYouPost setPage={setPage} />
                        : ''
                }

                {
                    activeLink === STRING.Forum ?
                        <CommunityPost />
                        : ''
                }

            </div>



        </div>
    )
}

export default Community
