export const IMAGES = {
    FAVICON: '/favicon.png',
    LOGO: '/images/bw_logo.png',
    LOGO_CH: '/images/logoCH.png',
    LOGIN: '/images/bw_login.png',
    PASSWORD: '/images/bw_password.png',
    PEACOCK: '/images/bw_peacock.png',
    PROFILE_IMAGE: '/images/bw_profile_image.jpeg',
    PROFILE_IMAGE_2:"/images/profile-image.png",
    POST_IMAGE: '/images/bw_post_image.jpeg',
    POST_IMAGE_2: '/images/bw_post_image2.jpeg',
    CONTACT_IMAGE: '/images/support_1.png',
    CARD_ICON: '/images/card_icon.png',
    UPLOAD_ICON: '/images/bw_upload_icon.png',
    WORKOUT_IMAGE: '/images/bw_workout_image.jpeg',
}

export const  BADGES = {
    MidnightWarrior: '/images/badges/midnight_warrior_1.jpg',
    MasterOfModes: '/images/badges/master_of_modes_1.jpg',
    HeartOfTheHub: '/images/badges/heart_of_the_hub_1.jpg',
    IMTheConsistency: '/images/badges/consistency_1.jpg',
    SocialSweater: '/images/badges/social_sweater_1.jpg',
    DedicationDemon: '/images/badges/dedication_demon_1.jpg',
    OfficeOlympian: '/images/badges/office_olympian_1.jpg',
    Gymfluencer: '/images/badges/gymfluencer_1.jpg',
    FitnessPhenom: '/images/badges/fitness_phenom_1.jpg',
    BuddyBuilder: '/images/badges/buddy_builder-1.jpg',
    CertifiedPersonalTrainer: '/images/badges/certified_personal_trainer_1.jpg',
    CertifiedStrength: '/images/badges/certifies_strength_condition_coach_1.jpg',
    InsightInstigator: '/images/badges/insight_instigator_1.jpg',
    WorkoutWeaver: '/images/badges/workout_weaver_1.jpg',
    CertifiedPhysicalTherapist: '/images/badges/certified_physical_therapist-1.jpg',
    Athlete: '/images/badges/athlete_1.jpg',
    DawnDominator: '/images/badges/dawn_dominator_1.jpg',
}
