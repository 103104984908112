import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react';
import authReducer from "./Data/Features/authSlice";
import lookupReducer from "./Data/Features/lookupSlice";
import dashboardReducer from "./Data/Features/dashboardSlice";
import communityReducer from "./Data/Features/communitySlice";
import staticContentReducer from "./Data/Features/staticContentSlice";
import userReducer from "./Data/Features/userSlice";
import otherUserReducer from "./Data/Features/otherUserSlice";
import searchReducer from "./Data/Features/searchSlice";
import trainingReducer from "./Data/Features/trainingSlice";
import cardReducer from "./Data/Features/cardSlice";
import languageReducer from "./Data/Features/languageSlice";

import defaultReducer from "./Data/Features/DefaultReducer";
import { baseApi } from "./Data/services/baseApi";
import { dashboardApi } from "./Data/services/dashboardApi";
import { communityApi } from "./Data/services/CommunityApi";
import { lookupApi } from "./Data/services/lookupApi";
import { staticContentApi } from "./Data/services/staticContentApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "./Data/services/userApi";
import { otherUserApi } from "./Data/services/otherUserApi";
import { userAuthApi } from "./Data/services/userAuthApi";
import { trainingApi } from "./Data/services/trainingApi";
import { cardApi } from "./Data/services/cardApi";
import { paymentApi } from "./Data/services/paymentApi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [cardApi.reducerPath]: cardApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [otherUserApi.reducerPath]: otherUserApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [lookupApi.reducerPath]: lookupApi.reducer,
    [staticContentApi.reducerPath]: staticContentApi.reducer,
    [trainingApi.reducerPath]: trainingApi.reducer,
    // Local storage Redux
    user: userReducer,
    card: cardReducer,
    search: searchReducer,
    otherUser: otherUserReducer,
    dashboard: dashboardReducer,
    community: communityReducer,
    lookups: lookupReducer,
    staticContent: staticContentReducer,
    authInfo: authReducer,
    default: defaultReducer,
    language: languageReducer,
    training: trainingReducer
  },
  // load from local storage
  preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      baseApi.middleware,
      dashboardApi.middleware,
      communityApi.middleware,
      userAuthApi.middleware,
      cardApi.middleware,
      paymentApi.middleware,
      userApi.middleware,
      otherUserApi.middleware,
      lookupApi.middleware,
      staticContentApi.middleware,
      trainingApi.middleware,
    ]),
});

function saveToLocalStorage(state) {
  try {
    const serialState = JSON.stringify(state)
    localStorage.setItem("reduxStore", serialState)
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("reduxStore");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

store.subscribe(() => saveToLocalStorage({ authInfo: store.getState().authInfo, language: store.getState().language }));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch) //todo:
